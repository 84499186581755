import { GlobalOutlined, UngroupOutlined } from '@ant-design/icons';
import { CodeBracketIcon, ServerStackIcon } from '@heroicons/react/24/outline';
import { Popover } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';

// Define size options
type Size = 'sm' | 'md' | 'lg';

interface AssetIconTypeProps {
  hoverText?: React.ReactNode;
  type: string;
  href?: string;
  size?: Size;
}

// Define size CSS classes
const sizeClasses: { [key in Size]: string } = {
  sm: 'w-8 h-8',
  md: 'w-10 h-10',
  lg: 'w-12 h-12'
};
const sizeIconClasses: { [key in Size]: string } = {
  sm: 'text-[12px] max-w-[20px]',
  md: 'text-[15px] max-w-[20px]',
  lg: 'text-[18px] max-w-[20px]'
};

const AssetIconType: React.FC<AssetIconTypeProps> = ({ hoverText, type, href, size = 'md' }) => {
  let bg = 'bg-blue-gray-50';
  let icon = <UngroupOutlined className={sizeIconClasses[size]} />;

  switch (type) {
    case 'web':
      bg = 'bg-[#D1FAE5]';
      icon = <GlobalOutlined className={sizeIconClasses[size]} />;
      break;
    case 'code':
      bg = 'bg-[#E9D5FF]';
      icon = <CodeBracketIcon className={sizeIconClasses[size]} />;
      break;
    case 'infrastructure':
      bg = 'bg-[#E0F2FE]';
      icon = <ServerStackIcon className={sizeIconClasses[size]} />;
      break;
    default:
      break;
  }

  const Component = (
    <div
      className={`flex items-center justify-center ${bg} ${sizeClasses[size]} rounded-xl`}
    >
      {icon}
    </div>
  );
  if (hoverText) {
    return (
      <Popover content={hoverText} title="Asset">
        {href ? <Link to={href}>{Component}</Link> : Component}
      </Popover>
    );
  }
  return (href ? <Link to={href}>{Component}</Link> : Component);
};

export default AssetIconType;
