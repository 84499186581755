import { CloseOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Form,
  Input,
  Space,
  Typography
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { reportItems } from '../../../../../redux/report/selectors';
import { report } from '../../../../../redux/report/actions';

const ReportDetails = () => {
  const dispatch = useDispatch();
  const reportData = useSelector(reportItems);

  // Update single objects like commanditaire and audite
  const handleUpdateSingleField = (objectName: string, field: string, value: any) => {
    dispatch<any>(report.updateReportFieldObject(objectName, field, value));
  };

  // Update list items like auditeurs
  const handleUpdateListField = (listName: string, index: number, field: string, value: any) => {
    dispatch<any>(report.handleUpdateReportDirect(listName, index, field, value));
  };

  const handleAddListItem = (listName: string) => {
    dispatch<any>(report.addListItem(listName));
  };

  const handleRemoveListItem = (listName: string, index: number) => {
    dispatch<any>(report.removeListItem(listName, index));
  };

  const handleUpdateReportDirect = (keyFiled: string, newname?: any) =>
    dispatch<any>(report.updateReportField(keyFiled, newname));

  return (
    <>
      <Form.Item
        className='w-full'
        layout="vertical"
        label="Report name"
      >
        <Input
          placeholder="Report name"
          defaultValue={reportData.report_name || ''}
          onChange={(e) => handleUpdateReportDirect('report_name', e.target.value!)}
        />
      </Form.Item>
      <Form.Item
        className='w-full'
        layout="vertical"
        label="Client name"
      >
        <Input
          placeholder="Client Name"
          defaultValue={reportData.client_name || ''}
          onChange={(e) => handleUpdateReportDirect('client_name', e.target.value!)}
        />
      </Form.Item>
      <Typography.Paragraph>
        Enter report details, including commanditaire, audite, and auditeurs information.
      </Typography.Paragraph>

      {/* Commanditaire Information */}
      <Card
        title="Commanditaire Information"
        size="small"
        type="inner"
        className="mb-5"
      >
        <Form name="commanditaire_form" autoComplete="off">
          <Space>
            <Form.Item name="name">
              <Input
                placeholder="Commanditaire Name"
                defaultValue={reportData.commanditaire?.name || ''}
                onChange={(e) => handleUpdateSingleField('commanditaire', 'name', e.target.value)}
              />
            </Form.Item>
            <Form.Item name="email">
              <Input
                placeholder="Email"
                defaultValue={reportData.commanditaire?.email || ''}
                onChange={(e) => handleUpdateSingleField('commanditaire', 'email', e.target.value)}
              />
            </Form.Item>
            <Form.Item name="status">
              <Input
                placeholder="Status"
                defaultValue={reportData.commanditaire?.status || ''}
                onChange={(e) => handleUpdateSingleField('commanditaire', 'status', e.target.value)}
              />
            </Form.Item>
          </Space>
        </Form>
      </Card>

      {/* Audite Information */}
      <Card
        title="Audite Information"
        size="small"
        type="inner"
        className="mb-5"
      >
        <Form name="audite_form" autoComplete="off">
          <Space>
            <Form.Item name="name">
              <Input
                placeholder="Audite Name"
                defaultValue={reportData.audite?.name || ''}
                onChange={(e) => handleUpdateSingleField('audite', 'name', e.target.value)}
              />
            </Form.Item>
            <Form.Item name="email">
              <Input
                placeholder="Email"
                defaultValue={reportData.audite?.email || ''}
                onChange={(e) => handleUpdateSingleField('audite', 'email', e.target.value)}
              />
            </Form.Item>
            <Form.Item name="status">
              <Input
                placeholder="Status"
                defaultValue={reportData.audite?.status || ''}
                onChange={(e) => handleUpdateSingleField('audite', 'status', e.target.value)}
              />
            </Form.Item>
          </Space>
        </Form>
      </Card>

      {/* Auditeurs Information (List) */}
      <Card
        title="Auditeurs Information"
        size="small"
        type="inner"
        className="mb-5"
      >
        <Form name="auditeurs_form" autoComplete="off">
          <Form.List name="auditeurs">
            {(fields, { add, remove }) => (
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: 16 }}
              >
                {fields.map((field, index) => (
                  <Space key={field.key} align="baseline">
                    <Form.Item {...field} name={[field.name, 'name']}>
                      <Input
                        placeholder="Auditeur Name"
                        defaultValue={reportData.auditeurs?.[index]?.name || ''}
                        onChange={(e) =>
                          handleUpdateListField('auditeurs', index, 'name', e.target.value)
                        }
                      />
                    </Form.Item>
                    <Form.Item {...field} name={[field.name, 'email']}>
                      <Input
                        placeholder="Email"
                        defaultValue={reportData.auditeurs?.[index]?.email || ''}
                        onChange={(e) =>
                          handleUpdateListField('auditeurs', index, 'email', e.target.value)
                        }
                      />
                    </Form.Item>
                    <Form.Item {...field} name={[field.name, 'status']}>
                      <Input
                        placeholder="Status"
                        defaultValue={reportData.auditeurs?.[index]?.status || ''}
                        onChange={(e) =>
                          handleUpdateListField('auditeurs', index, 'status', e.target.value)
                        }
                      />
                    </Form.Item>
                    <CloseOutlined
                      onClick={() => {
                        remove(field.name); // Remove from form state
                        handleRemoveListItem('auditeurs', index); // Remove from Redux
                      }}
                    />
                  </Space>
                ))}
                <Button
                  type="dashed"
                  onClick={() => {
                    add(); // Add to form state
                    handleAddListItem('auditeurs'); // Add to Redux
                  }}
                  block
                >
                  + Add Auditeur
                </Button>
              </div>
            )}
          </Form.List>
        </Form>
      </Card>
    </>
  );
};

export default ReportDetails;
