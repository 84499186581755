import { Tag } from 'antd';
import React from 'react';

type RiskScoreTagProps = {
  risk?: number;
  className?: string;
  isStatus?: boolean;
  size?: 'sm' | 'md' | 'lg'; // Sizes: small, medium, large
};

const getBgColor = (score: number) => {
  if (score <= 30) return 'bg-green-500'; // Low
  if (score <= 60) return 'bg-yellow-500 !text-black'; // Medium
  if (score <= 90) return 'bg-red-500'; // High
  return 'bg-purple-500'; // Critical
};

const getStatus = (score: number) => {
  if (score <= 30) return 'Low';
  if (score <= 60) return 'Medium';
  if (score <= 90) return 'High';
  return 'Critical';
};

const getSizeStyles = (size: 'sm' | 'md' | 'lg') => {
  switch (size) {
    case 'sm':
      return 'text-sm min-w-[20px] px-1 h-[20px]';
    case 'md':
      return 'text-base min-w-[30px] px-2 h-[25px]';
    case 'lg':
      return 'text-lg min-w-[40px] px-3 h-[30px]';
    default:
      return '';
  }
};

const RiskScoreTag: React.FC<RiskScoreTagProps> = ({ risk, className = '', isStatus = false, size = 'md' }) => {
  if (risk == null) return <Tag color="default">N/A</Tag>;

  const sizeStyles = getSizeStyles(size);
  const bgColor = getBgColor(risk);
  const content = isStatus ? getStatus(risk) : risk;

  return (
    <div
      className={`flex justify-center items-center rounded-md text-white ${bgColor} font-bold ${sizeStyles} ${className}`}
    >
      {content}
    </div>
  );
};

type RiskScoreContainerProps = {
  riskScore: number;
  size?: 'sm' | 'md' | 'lg';
};

const RiskScoreContainer: React.FC<RiskScoreContainerProps> = ({ riskScore, size = 'md' }) => (
  <div
    className="mx-auto mt-4.5 mb-5.5 grid max-w-[200px] grid-cols-1 rounded-md border border-stroke py-2.5 shadow-1 dark:border-strokedark dark:bg-[#37404F]"
  >
    <div 
      className="flex flex-col items-center justify-center gap-1 border-stroke px-4 dark:border-strokedark xsm:flex-row"
    >
      <span className="text-lg font-bold">Risk score</span>
      <RiskScoreTag risk={riskScore} size={size} />
    </div>
  </div>
);

export { RiskScoreContainer };
export default RiskScoreTag;
