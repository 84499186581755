import { notification } from 'antd';

import codeMessage from './codeMessage';

const successHandler = (response, options = {
  notifyOnSuccess: false,
  notifyOnFailed: true
}) => {
  const { data, status } = response;
  
  if (status === 200 || status === 201 || status === 204) {
    const message = response.response.data && response.response.data.message;
    const successText = message || codeMessage[status];

    if (options.notifyOnSuccess) {
      notification.config({
        duration: 5,
        maxCount: 5
      });
      notification.success({
        message: 'Request success',
        description: successText
      });
    }
  } else {
    // Correctly handling error messages from failed responses
    const errorResponseData = response.response && response.response.data; // Ensure response.response exists
    const message = errorResponseData && errorResponseData.message; // Extract message if available
    const errorText = message || codeMessage[status]; // Use status from the outer response directly
    if (options.notifyOnFailed) {
      notification.config({
        duration: 4,
        maxCount: 2
      });
      notification.error({
        message: `Request error ${status}`,
        description: errorText
      });
    }
  }
};

export default successHandler;
