import { Avatar, Card, List } from 'antd';
import {
  EditOutlined,
  SettingOutlined,
  DeleteFilled
} from '@ant-design/icons';
import {
  Button
} from '@material-tailwind/react';
import Meta from 'antd/es/card/Meta';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import ReportList2 from './list';
import { useDispatch, useSelector } from 'react-redux';
import { reportList } from '../../../../redux/report/selectors';

const ReportList = () => {
  const { reports, loading, hasMore, currentPage, total_records, totalPages } = useSelector(reportList);

  const data: any = [];
  const navigate = useNavigate();
  return <div className='my-10'>
    <div className="flex mb-5 items-center gap-5">
      <Button
        variant="filled"
        className="bg-primary flex items-center"
        size="sm"
        onClick={() => {
          navigate('/report/createNew');
        }}
      >
        <PlusIcon
          strokeWidth={4}
          className="h-4 w-4 mr-4"
        /> Create New Report
      </Button>

      <div className='text-lg font-bold'>{total_records} Report</div>
    </div>
    <ReportList2 />
    {/* <List
      grid={{ gutter: 16, column: 4 }}
      dataSource={data}
      renderItem={(item: any) => (
        <List.Item>
          <Card
            style={{ width: 300, marginTop: 16 }}
            actions={[
              <SettingOutlined
                key="setting"
              />, <EditOutlined
                key="edit"
              />, <DeleteFilled
                key="ellipsis"
              />
            ]}
          >
            <Meta
              title={item?.title}
              description="This is the description"
            />
          </Card>
        </List.Item>
      )}
    /> */}
  </div>
  ;
};

export default ReportList;
