import { 
  FilePdfOutlined, 
  FileWordOutlined, 
  UserOutlined 
} from '@ant-design/icons';
import { Radio, RadioChangeEvent, Typography } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reportItems } from '../../../../../redux/report/selectors';
import { report } from '../../../../../redux/report/actions';

const ReportType = () => {

  const dispatch = useDispatch();
  const reportData = useSelector(reportItems);
  const handleUpdateReportDirect = (keyFiled: string, newname?: any) =>
    dispatch<any>(report.updateReportField(keyFiled, newname));

  const [value, setValue] = useState(1);
  const onChange = (e: RadioChangeEvent) => {
    const { value } = e.target;
    handleUpdateReportDirect('type', value === 1 ? 'docx' : 'pdf');
    setValue(value);
  };

  const [language, setLanguage] = useState(reportData.language == 'en' ? 1 : 2);
  const onChangeLanguage = (e: RadioChangeEvent) => {
    const { value } = e.target;
    handleUpdateReportDirect('language', value === 1 ? 'en' : 'fr');
    setLanguage(value);
  };
  return (
    <>
      <Typography.Paragraph>
                Select the format for the report
      </Typography.Paragraph>
      <Radio.Group
        onChange={onChange}
        value={value}
        className="w-full flex gap-4"
      >
        <Radio
          value={1}
          className={`w-full text-center border-2 ${value === 1 ? 'border-blue-500 text-blue-600' : 'border-blue-gray-400 text-blue-gray-700'
          } rounded-md h-15 p-4 transition-all duration-200 ease-in-out flex flex-row items-center`}
        >
          <div className="flex gap-5">
            <FileWordOutlined className="text-2xl mb-1" />
            <div className='flex flex-col justify-start items-start'>
              <div
                className="block text-lg font-medium"
              >Docx</div>
            </div>
          </div>
        </Radio>
      </Radio.Group>

      <br/><br/>
      <Typography.Paragraph>
                Select the format for the report
      </Typography.Paragraph>
      <Radio.Group
        onChange={onChangeLanguage}
        value={language}
        className="w-full flex gap-4"
      >
        <Radio
          value={1}
          className={`w-full text-center border-2 ${language === 1 ? 'border-blue-500 text-blue-600' : 'border-blue-gray-400 text-blue-gray-700'
          } rounded-md h-15 p-4 transition-all duration-200 ease-in-out flex flex-row items-center`}
        >
          <div className="flex gap-5">
            <div className='flex flex-col justify-start items-start'>
              <div
                className="block text-lg font-medium"
              >English</div>
            </div>
          </div>
        </Radio>
        <Radio
          value={2}
          className={`w-full text-center border-2 ${language === 2 ? 'border-blue-500 text-blue-600' : 'border-blue-gray-400 text-blue-gray-700'
          } rounded-md h-15 p-4 transition-all duration-200 ease-in-out flex flex-row items-center`}
        >
          <div className="flex gap-5">
            <div className='flex flex-col justify-start items-start'>
              <div
                className="block text-lg font-medium"
              >French</div>
            </div>
          </div>
        </Radio>
      </Radio.Group>
    </>
  );
};

export default ReportType;