import { Suspense, useEffect, useState } from 'react';

import {
  Route,
  Routes
} from 'react-router-dom';

import DefaultLayout from './components/layout/DefaultLayout';
import routes from './routes';
import { Provider } from 'react-redux';
import store from './redux/store';
import { ThemeProvider } from '@material-tailwind/react';
import Dashboard from './pages/dashboard/Dashboard';
import LoginPage from './pages/login/LoginPage';
import ProtectedRoute from './routes/ProtectedRoute';
import Loading from './components/UI/loading/Loading';
import NotFoundPage from './pages/404/404Page';
import ReportCreatePage from './pages/report/Create/ReportCreatePage';
import { App as AntdApp } from 'antd'; // Import the App wrapper

function App() {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
  }, []);

  if (loading) return <Loading />;

  return (
    <>
      <AntdApp>
        <Provider store={store}>
          <ThemeProvider>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route element={<DefaultLayout />}>
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
              </Route>
              {routes.map((route, index) => {
                const { path, component: Component } = route;
                return (
                  <Route element={<DefaultLayout />} key={index}>
                    <Route
                      key={index}
                      path={path}
                      element={
                        <ProtectedRoute>
                          <Suspense fallback="loading">
                            <Component />
                          </Suspense>
                        </ProtectedRoute>
                      }
                    />
                  </Route>
                );
              })}
              {/* Catch-all Route for 404 Not Found */}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </ThemeProvider>
        </Provider>
      </AntdApp>
    </>
  );
}

export default App;
