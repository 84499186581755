import {
  Button,
  Form as AntdForm,
  Checkbox,
  GetProp,
  Divider,
  Spin,
  Steps,
  message,
  Typography
} from 'antd';
import {
  useCallback,
  useEffect,
  useState
} from 'react';
import { VULNERABILITY_STATUS } from '../../../../config/enum';
import projectService from '../../../../service/project.service';
import { useDispatch, useSelector } from 'react-redux';
import { report } from '../../../../redux/report/actions';
import { reportItems } from '../../../../redux/report/selectors';
import CardZeus from '../../../UI/card/Card.ui';
import { debounce } from 'lodash';
import { FileSyncOutlined, PlusOutlined } from '@ant-design/icons';
import ReportType from './steps/reportType';
import ReportSummary from './steps/reportSummary';
import ReportDetails from './steps/reportDetails';
import ReportVulnerabilityData from './steps/reportVulnerabilityData';
import ReportDone from './steps/reportDone';
import {
  generateReport
} from '../../../../service/report.service';

const ReportCreateNew = () => {

  const dispatch = useDispatch();
  const reportData = useSelector(reportItems);

  const stepsSteps = [
    {
      title: 'Choose Report Type',
      content: <ReportType />
    }, {
      title: 'Report Details',
      content: <ReportDetails />
    }, {
      title: 'Summary',
      content: <ReportSummary />
    }, {
      title: 'Vulnerability Data',
      content: <ReportVulnerabilityData />
    }, {
      title: 'Generate Report',
      content: <ReportDone />
    }
  ];

  const [loadingPdfGeneration, setLoadingPdfGeneration] = useState(false);
  const [current, setCurrent] = useState(0);

  return <div>
    <Spin spinning={loadingPdfGeneration} fullscreen />
    <div>
      <div
        className="w-[100%] px-10 "
      >
        <div className="flex gap-10 gap-y-10 justify-center">
          <div className="w-full max-w-[800px]">
            <div className="flex flex-col justify-center items-center mb-4">
              <Typography.Title
                level={3}
              >Create Report</Typography.Title>
              <div
                className='text-blue-gray-600 text-lg'
              >Create new report for your project</div>
            </div>
            <Steps
              current={current}
              items={stepsSteps}
              size="small"
              progressDot
            />
            <CardZeus className='!p-1 hidden'>
              <div>
                <Typography.Paragraph
                  type='secondary'
                  style={{ marginBottom: 5 }}
                >
                  Step {current + 1}
                </Typography.Paragraph>
                <Typography.Title
                  level={4}
                  style={{ margin: 0 }}
                >
                  {stepsSteps[current].title}
                </Typography.Title>
                <Divider />
                {stepsSteps[current].content}
              </div>
            </CardZeus>
            <div
              style={{ marginTop: 24 }}
              className='flex justify-end'
            >
              {(current > 0 && current < stepsSteps.length - 1) && (
                <Button
                  style={{ margin: '0 8px' }}
                  onClick={() => setCurrent(current - 1)}
                >
                  Previous
                </Button>
              )}
              {(current < stepsSteps.length - 2) && (
                <Button
                  type="primary"
                  disabled={loadingPdfGeneration}
                  onClick={() => setCurrent(current + 1)}
                >
                  Next
                </Button>
              )}
              {current === stepsSteps.length - 2 && (
                <Button
                  disabled={!reportData.project}
                  type="primary"
                  icon={<FileSyncOutlined />}
                  onClick={async () => {
                    setLoadingPdfGeneration(true);
                    const url = await generateReport(reportData);
                    dispatch<any>(report.updateReportField('success', url ? true : false));
                    dispatch<any>(report.updateReportField('url', url));
                    setLoadingPdfGeneration(false);
                    setCurrent(current + 1);
                    report.updateReportField('reportDone', 'docx');
                  }}
                >
                  Generate Report
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div >
  </div >;
};

export default ReportCreateNew;
