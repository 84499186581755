import { EnvelopeIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import InputField from '../../UI/input/InputFiled';
import { Navigate, useNavigate } from 'react-router-dom';
import SignupText from './SignupText.feature';
import * as Yup from 'yup';
import {
  Formik,
  Form
} from 'formik';
import { useEffect, useState } from 'react';
import ButtonUI from '../../UI/button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth } from '../../../redux/auth/selectors';
import { login } from '../../../redux/auth/actions';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address') // Validates the email format
    .required('Email is required'),// Marks the field as required
  password: Yup.string()
    .min(0, 'Password must be at least 8 characters') // Minimum length validation
    .required('Password is required') // Marks the field as required
});

interface LoginFormValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

const LoginForm = () => {
  const { isLoading, isSuccess } = useSelector(selectAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState<LoginFormValues>({
    email: '',
    password: '',
    rememberMe: false
  });

  useEffect(() => {
    // Retrieve user email from local storage on initial render
    const rememberedEmail = localStorage.getItem('rememberedEmail');
    if (rememberedEmail) {
      setInitialValues((prevValues) => ({
        ...prevValues,
        email: rememberedEmail,
        rememberMe: true // Set rememberMe to true if an email is found in local storage
      }));
    }
  }, []);

  useEffect(() => {
    // Redirect to the last visited path or root upon successful login
    if (isSuccess) {
      const lastVisitedPath = localStorage.getItem('lastVisitedPath');
      if (lastVisitedPath) {
        navigate(lastVisitedPath); // Redirect to the stored path
        localStorage.removeItem('lastVisitedPath'); // Clear stored path after redirection
      } else {
        navigate('/'); // Default to root path if no path was stored
      }
    }
  }, [isSuccess, navigate]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        dispatch<any>(login({
          email: values.email,
          password: values.password
        }));
        // window.location.href = '/';

        // Check if rememberMe checkbox is checked
        if (values.rememberMe) {
          // Store email in local storage if rememberMe is true
          localStorage.setItem('rememberedEmail', values.email);
        } else {
          // Remove email from local storage if rememberMe is false
          localStorage.removeItem('rememberedEmail');
        }

        actions.setSubmitting(false);
      }}
    >
      {({ isSubmitting, isValid, dirty, handleChange, values }) => (

        <Form>
          <InputField
            label="Email"
            placeholder="Enter your email"
            size='large'
            iconRight={<EnvelopeIcon />}
            className="mb-10"
            name='email'
            id='email'
          />

          <InputField
            label="Password"
            placeholder="************"
            size='large'
            iconRight={<LockClosedIcon />}
            type="password"
            className='mb-5'
            name='password'
          />

          {/* Add Remember Me checkbox */}
          <div className="flex items-center mb-10">
            <input
              type="checkbox"
              id="rememberMe"
              name="rememberMe"
              checked={values.rememberMe}
              onChange={handleChange}
              className="mr-2"
            />
            <label htmlFor="rememberMe" className="text-sm">
              Remember Me
            </label>
          </div>

          <ButtonUI
            type='submit'
            size='lg'
            className='bg-primary mb-5'
            fullWidth
            disabled={isSubmitting || !isValid || isLoading}
            name='Sign In'
            loading={isLoading}
          />
          {/* <SignupText /> */}
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;