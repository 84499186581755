import { Formik, Form } from 'formik';
import { useEffect, useState } from 'react';
import {
  Modal,
  Button as AntButton,
  Select,
  Tabs,
  Input,
  Form as AntdForm
} from 'antd';
import * as Yup from 'yup';
import { createUser, getRoleList } from '../../../../service/user.service';
import { AddUserProps } from './types';

interface CreateUserProps {
  isVisible: boolean;
  onClose: () => void;
  onUserCreated: () => void;
}

export default function CreateUser({ isVisible, onClose, onUserCreated }: CreateUserProps) {
  const initialValues: AddUserProps = {
    role: '',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: ''
  };

  const validationSchema = Yup.object().shape({
    role: Yup.string().required('Role is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match').required('Confirm password is required')
  });

  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [fieldsDisabled, setFieldsDisabled] = useState(true);

  useEffect(() => {
    const getRoles = async () => {
      setLoadingRoles(true);
      const roles = await getRoleList();
      setRoles(
        roles.map((v: any) => ({
          label: v.name,
          value: v.name,
          id: v.name
        }))
      );
      setLoadingRoles(false);
    };
    getRoles();
  }, []);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: any) => {
    const email = e.target.value;
    setFieldValue('email', email);

    const emailSchema = Yup.string().email('Invalid email address').required('Email is required');

    emailSchema
      .validate(email)
      .then(() => {
        setFieldsDisabled(false);
      })
      .catch(() => {
        setFieldsDisabled(true);
      });
  };

  return (
    <Modal
      title="Create User"
      visible={isVisible}
      onCancel={onClose}
      footer={null}  // Footer handled manually
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          setIsLoading(true);
          const response = await createUser(values);
          if (!(response && response.success === false)) {
            actions.setSubmitting(false);
            actions.resetForm();
            onUserCreated();
            onClose();
          } else {
            actions.setSubmitting(false);
          }
          setIsLoading(false);
          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting, isValid, dirty, handleSubmit, setFieldValue, values, touched, errors }) => (
          <Form>
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="General" key="1">
                <AntdForm.Item
                  label="E-mail"
                  validateStatus={touched.email && errors.email ? 'error' : ''}
                  help={touched.email && errors.email ? errors.email : ''}
                  className='flex flex-col'
                  required
                  layout='vertical'
                >
                  <Input
                    name="email"
                    placeholder="Email"
                    onChange={(e) => handleEmailChange(e, setFieldValue)}
                    value={values.email}
                  />
                </AntdForm.Item>

                <AntdForm.Item
                  label="Role"
                  validateStatus={touched.role && errors.role ? 'error' : ''}
                  help={touched.role && errors.role ? errors.role : ''}
                  layout='vertical'
                  required
                >
                  <Select
                    loading={loadingRoles}
                    placeholder="Please select roles..."
                    options={[{
                      label: 'System Admin',
                      value: 'SYS_ADMIN'
                    }]}
                    // options={roles}
                    onChange={(value) => setFieldValue('role', value)}
                    disabled={fieldsDisabled}
                    value={values.role}
                  />
                </AntdForm.Item>

                <div className="grid grid-cols-2 gap-5">
                  <AntdForm.Item
                    label="First Name"
                    validateStatus={touched.firstName && errors.firstName ? 'error' : ''}
                    help={touched.firstName && errors.firstName ? errors.firstName : ''}
                    layout='vertical'
                    required
                  >
                    <Input
                      name="firstName"
                      placeholder="First Name"
                      onChange={(e) => setFieldValue('firstName', e.target.value)}
                      disabled={fieldsDisabled}
                      value={values.firstName}
                    />
                  </AntdForm.Item>

                  <AntdForm.Item
                    label="Last Name"
                    required
                    className='flex flex-col'
                    validateStatus={touched.lastName && errors.lastName ? 'error' : ''}
                    help={touched.lastName && errors.lastName ? errors.lastName : ''}
                    layout='vertical'
                  >
                    <Input
                      name="lastName"
                      placeholder="Last Name"
                      onChange={(e) => setFieldValue('lastName', e.target.value)}
                      disabled={fieldsDisabled}
                      value={values.lastName}
                    />
                  </AntdForm.Item>
                </div>

                <div className="grid grid-cols-2 gap-5">
                  <AntdForm.Item
                    label="Password"
                    validateStatus={touched.password && errors.password ? 'error' : ''}
                    help={touched.password && errors.password ? errors.password : ''}
                    layout='vertical'
                    required
                  >
                    <Input.Password
                      name="password"
                      placeholder="Password"
                      onChange={(e) => setFieldValue('password', e.target.value)}
                      disabled={fieldsDisabled}
                      value={values.password}
                    />
                  </AntdForm.Item>

                  <AntdForm.Item
                    required
                    className='flex flex-col'
                    label="Confirm Password"
                    validateStatus={touched.confirmPassword && errors.confirmPassword ? 'error' : ''}
                    help={touched.confirmPassword && errors.confirmPassword ? errors.confirmPassword : ''}
                    layout='vertical'
                  >
                    <Input.Password
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      onChange={(e) => setFieldValue('confirmPassword', e.target.value)}
                      disabled={fieldsDisabled}
                      value={values.confirmPassword}
                    />
                  </AntdForm.Item>
                </div>

                <div className="flex justify-end gap-2 mt-4">
                  <AntButton
                    type="default"
                    onClick={onClose}
                  >
                    Cancel
                  </AntButton>
                  <AntButton
                    type="primary"
                    loading={isLoading}
                    disabled={!isValid || !dirty || isSubmitting}
                    onClick={(e) => handleSubmit()}
                  >
                    Create User
                  </AntButton>
                </div>
              </Tabs.TabPane>
            </Tabs>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
