import Title from 'antd/es/typography/Title';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface PIE_CHART_PROPS {
  seriesDefault?: number[];
  height?: number;
  title?: string;
  label?: string[];
  colors?: string[];
  type?:
  | 'line'
  | 'area'
  | 'bar'
  | 'pie'
  | 'donut'
  | 'radialBar'
  | 'scatter'
  | 'bubble'
  | 'heatmap'
  | 'candlestick'
  | 'boxPlot'
  | 'radar'
  | 'polarArea'
  | 'rangeBar'
  | 'rangeArea'
  | 'treemap';
  showPercentage?: boolean;
  showDataLabels?: boolean;
  compact?: boolean;
  monochrome?: boolean;
  showLegend?: boolean;
}

const PieChart: React.FC<PIE_CHART_PROPS> = ({
  seriesDefault = [],
  label = ['critical', 'high', 'medium', 'low', 'information'],
  colors = [],
  type = 'donut',
  showPercentage = true,
  showDataLabels = false,
  title = '',
  compact = false,
  monochrome = false,
  showLegend = true,
  height = 400
}) => {
  const total = seriesDefault.reduce((acc, curr) => acc + curr, 0);
  const percentages = seriesDefault.map((value) =>
    ((value / total) * 100).toFixed(2)
  );

  return (
    <div className="p-4">
      {/* Title */}
      {type === 'pie' && (
        <Title
          level={4}
          className="text-center text-gray-800 font-semibold mb-4"
        >
          Total: {total}
        </Title>
      )}

      {/* Chart Container */}
      <div className="mb-4">
        <div
          id="pieChart"
          className="mx-auto flex justify-center items-center w-full h-full"
          style={{ minHeight: height }}
        >
          {total > 0 && (
            <ReactApexChart
              options={{
                title: {
                  text: title,
                  align: 'center',
                  style: {
                    fontSize: '12px',
                    color: '#333'
                  }
                },
                chart: {
                  height: '100%',
                  width: '100%', // Changed from 200% to 100%
                  type: type,
                  toolbar: {
                    show: false // Hides unnecessary toolbar for a cleaner look
                  },
                  animations: {
                    enabled: true,
                    easing: 'easeinout',
                    speed: 800
                  },
                  dropShadow: {
                    enabled: false // Disable shadow
                  }
                },
                dataLabels: {
                  background: {
                    enabled: false
                  },
                  enabled: showDataLabels,
                  distributed: true,
                  dropShadow: {
                    enabled: false
                  },
                  style: {
                    fontSize: '15px',
                    fontFamily: 'Arial, sans-serif',
                    fontWeight: 500,
                    colors: ['#fff']
                  }
                },
                colors: colors,
                labels: label,
                theme: {
                  monochrome: {
                    enabled: monochrome,
                    color: '#255aee',
                    shadeTo: 'light',
                    shadeIntensity: 1
                  }
                },
                legend: {
                  show: showLegend,
                  position: 'bottom',
                  horizontalAlign: 'center',
                  markers: {},
                  fontSize: '14px',
                  fontFamily: 'Arial, sans-serif',
                  labels: {
                    useSeriesColors: true
                  },
                  formatter: function (val, opts) {
                    const index = opts.seriesIndex;
                    const percentage = Math.round(
                      parseFloat(percentages[index])
                    );
                    const label = val;
                    return `${label}`;
                  }
                },
                fill: {
                  opacity: 1
                },
                tooltip: {
                  theme: 'light',
                  style: {
                    fontSize: '13px',
                    fontFamily: 'Arial, sans-serif'
                  },
                  onDatasetHover: {
                    highlightDataSeries: true
                  }
                },
                plotOptions: {
                  pie: {
                    donut: {
                      size: compact ? '70%' : '75%',
                      labels: {
                        show: true,
                        total: {
                          show: true,
                          label: 'Total',
                          fontSize: '16px',
                          fontWeight: 600,
                          color: '#333' // Changed to visible color
                        }
                      }
                    }
                  }
                }
              }}
              series={seriesDefault}
              type={type}
              height={height}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PieChart;
