import { useSelector } from 'react-redux';
import { createColumnHelper } from '@tanstack/react-table';
import UserType from './type';
import getCreatedUpdatedColumns from '../../../utils/getCreatedUpdatedColumns.utils';
import { Link, useNavigate } from 'react-router-dom';
import { Tag, Tooltip } from 'antd';
import { selectListItems } from '../../../../redux/users/selectors';
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons';

const columnHelper = createColumnHelper<UserType>();

export const UserConfig: any = {
  columns: [
    columnHelper.accessor('email', {
      header: 'email',
      enableHiding: false,
      size: 30,
      cell: props => {
        const { result: listResult } = useSelector(selectListItems);
        const searchTerm = listResult?.search?.trim() || '';
        const name = props.row.original.email;

        const highlightText = (text = '', highlight = '') => {
          if (!highlight) return text;
          const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
          return (
            <span>
              {parts.map((part, index) =>
                part.toLowerCase() === highlight.toLowerCase() ? (
                  <span
                    key={index}
                    style={{ backgroundColor: 'yellow' }}
                  >{part}</span>
                ) : (
                  part
                )
              )}
            </span>
          );
        };
        return (
          <div className='text-overflow max-w-[400px]'>
            <div
              className='font-bold hover:underline overflow-hidden'
            >
              <div className="p-2 truncate">
                {highlightText(name, searchTerm)}
              </div>
            </div>
          </div>
        );
      }
    }), columnHelper.accessor('firstName', {
      header: 'First Name',
      enableHiding: false,
      size: 30
    }), columnHelper.accessor('lastName', {
      header: 'Last Name',
      enableHiding: false,
      size: 30
    }), columnHelper.accessor('enabled', {
      header: 'Status',
      enableHiding: false,
      size: 30,
      cell: props => (
        <Tooltip title={props.row.original.enabled ? 'Enabled' : 'Disabled'}>
          {
            props.row.original.enabled ?
              <CheckOutlined className='text-green-600 text-[15px]' /> :
              <CloseOutlined className='text-red-600 text-[15px]' />
          }
        </Tooltip>
      )
    }), columnHelper.accessor('role', {
      header: 'Role',
      enableHiding: false,
      size: 30,
      cell: props => (
        <Tag
          bordered={true}
          color='geekblue'
        >
          {props.row.original.role === 'SYS_ADMIN' || props.row.original.role === 'admn' ? 'System Admin' : ''}
        </Tag>
      )
    }), columnHelper.accessor('createdTimestamp', {
      header: 'Created',
      enableHiding: false,
      size: 150,
      cell: props => {
        let date = new Date(props.row.original.createdTimestamp);
        return date.toLocaleString('fr-FR');
      }
    })
  ],

  visibility: {
    // Define which columns should be visible or hidden
  }
};

