import {
  CloudDownloadOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  UserOutlined
} from '@ant-design/icons';
import {
  Button,
  Radio,
  RadioChangeEvent,
  Result,
  Typography
} from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reportItems } from '../../../../../redux/report/selectors';
import { report } from '../../../../../redux/report/actions';
import { useNavigate } from 'react-router-dom';
import reportService, { 
  generateReport 
} from '../../../../../service/report.service';

const ReportDone = () => {

  const dispatch = useDispatch();
  const reportData = useSelector(reportItems);

  const handleUpdateReportDirect = (keyFiled: string, newname?: any) =>
    dispatch<any>(report.updateReportField(keyFiled, newname));

  const [value, setValue] = useState(1);

  const onChange = (e: RadioChangeEvent) => {
    const { value } = e.target;
    handleUpdateReportDirect('reportDone', value === 1 ? 'docx' : 'pdf');
    setValue(value);
  };
  const navigate = useNavigate();
  const handleList = () => navigate('/report');

  const getStatus = () => {
    const { success, url } = reportData;
    if (success === true && url) return 'success';
    else if (success === false) return 'error';
    else return 'info';
  };
  const getTitle = () => {
    const { success, url } = reportData;
    if (success === true && url) return 'Report generated successfully';
    else if (success === false) return 'Report generation failed';
    else return 'Report generation in progress';
  };
  const getSubtitle = () => {
    const { success, url } = reportData;
    if (success === true && url) return 'Your report has been successfully generated and is ready for download';
    else if (success === false) return 'Report generation failed';
    else return 'Report generation in progress';
  };
  return (
    <>
      <Result
        status={getStatus()}
        title={getTitle()}
        subTitle={getSubtitle()}
        extra={[
          <Button
            onClick={() => handleList()}
          >Return to list</Button>, <Button
            icon={<CloudDownloadOutlined />}
            type="primary"
            key="download"
            disabled={!(getStatus() == 'success')}
            onClick={()=> reportService.downloadDoc(reportData.url, 'docx')}
          >
            Download Report
          </Button>
        ]}
      />
    </>
  );
};

export default ReportDone;