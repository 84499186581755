import React from 'react';
import ReactApexChart from 'react-apexcharts';

const CustomApexChart = ({ data }: any) => (
  <div className="chart-container">
    <ReactApexChart
      series={[{
        name: 'Total vuln',
        data: data?.data ?? []
      }]}
      options={{
        chart: {
          type: 'area',
          animations: {
            enabled: false,
            easing: 'easeinout'
          },
          group: 'sparklines',
          background: '',
          foreColor: '#333',
          fontFamily: 'Roboto',
          height: 10,
          id: 'Hyhoa',
          stackOnlyBar: false,
          toolbar: {
            show: false,
            tools: {
              selection: false,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true
            }
          },
          width: 480,
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            borderRadiusApplication: 'end',
            borderRadiusWhenStacked: 'last',
            hideZeroBarsWhenGrouped: false,
            isFunnel3d: true,
            dataLabels: {
              total: {
                enabled: false,
                style: {
                  color: '#373d3f',
                  fontSize: '12px',
                  fontWeight: 600
                }
              }
            }
          }
        },
        labels: data?.series && [],
        dataLabels: {
          enabled: false,
          style: {
            fontWeight: 700
          },
          dropShadow: {
            blur: 0
          }
        },
        grid: {
          show: false,
          padding: {
            right: 0,
            left: 0
          }
        },
        stroke: {
          width: 3,
          fill: {
            type: 'solid',
            opacity: 0.85
          }
        },
        xaxis: {
          categories: data?.series && [],
          tickAmount: 2,
          position: 'bottom',
          labels: {
            show: false,
            trim: false
          },
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          }
        },
        yaxis: {
          seriesName: data?.series && [],
          show: false,
          tickAmount: 8
        }
      }}
      type="area"
      height={120}
    />
  </div>
);

export default CustomApexChart;
