export const RESET_STATE = 'VULN_RESET_STATE';
export const CURRENT_ITEM = 'VULN_CURRENT_ITEM';

export const REQUEST_LOADING = 'VULN_REQUEST_LOADING';
export const REQUEST_SUCCESS = 'VULN_REQUEST_SUCCESS';
export const REQUEST_FAILED = 'VULN_REQUEST_FAILED';

export const CURRENT_ACTION = 'VULN_CURRENT_ACTION';
export const RESET_ACTION = 'VULN_RESET_ACTION';

export const UPDATE_VISIBILITY = 'UPDATE_VISIBILITY';

export const RESET_LIST = 'VULN_RESET_LIST';

export const UPDATE_CURRENT_ITEM_FIELD = 'UPDATE_CURRENT_ITEM_FIELD';

export const UPDATE_FILTER = 'VULN_UPDATE_FILTER';

export const SET_FILTER_CHANGED_FLAG = 'SET_FILTER_CHANGED_FLAG';

export const UPDATE_SMTP_CONFIG = 'UPDATE_SMTP_CONFIG';

export const UPDATE_EVIDENCE_LIST = 'UPDATE_EVIDENCE_LIST';