// VULNERABILITY
// status: open, re-opened, closed, risk-accepted
// confidence:  certain, firm, tentative
// ease_of_resolution: trivial, simple, moderate, difficult, infeasible
// severity: unclassified, informational, information, low, medium, high, critical

// ASSET: 
// env : dev, staging, pre_prod, prod
// criticality : critical, high, medium, low, not_critical
// DATA_SENSITIVITY : public, internal_use, confidential, secret 
// NETWORK_EXPOSURE : internet, internal 
// COMPLIANCE : compliant, non_Compliant, not_applicable

export const VULNERABILITY_STATUS = [
  {
    label: 'Open',
    value: 'open'
  }, {
    label: 'Analysed',
    value: 'analysed'
  }, {
    label: 'Fixed',
    value: 'fixed'
  }, {
    label: 'Reopened',
    value: 'reopened'
  }, {
    label: 'False Positive',
    value: 'false_positive'
  }, {
    label: 'Risk Accepted',
    value: 'risk_accepted'
  }
];

export const VULNERABILITY_CONFIDENCE = [
  {
    label: 'Certain',
    value: 'certain'
  }, {
    label: 'Firm',
    value: 'firm'
  }, {
    label: 'Tentative',
    value: 'tentative'
  }
];

export const VULNERABILITY_EASE_OF_RESOLUTION = [{
  label: 'Simple',
  value: 'simple'
}, {
  label: 'Moderate',
  value: 'moderate'
}, {
  label: 'High',
  value: 'high'
}, {
  label: 'Difficult',
  value: 'difficult'
}
];
export const EXPLOIT_DIFFICULTY = [{
  label: 'High',
  value: 'high'
}, {
  label: 'Difficult',
  value: 'difficult'
}, {
  label: 'Moderate',
  value: 'moderate'
}, {
  label: 'Easy',
  value: 'easy'
}
];

export const AVAILABILITY = [{
  value: 'Very High',
  label: 'Very High'
}, {
  value: 'High',
  label: 'High'
}, {
  value: 'Medium',
  label: 'Medium'
}, {
  value: 'Low',
  label: 'Low'
}];

export const VULNERABILITY_SEVERITY = [
  {
    label: 'Critical',
    value: 'critical'
  }, {
    label: 'High',
    value: 'high'
  }, {
    label: 'Medium',
    value: 'medium'
  }, {
    label: 'Low',
    value: 'low'
  }, {
    label: 'Information',
    value: 'information'
  }
];

export const ASSET_ENV = [
  {
    label: 'Dev',
    value: 'dev'
  }, {
    label: 'Staging',
    value: 'staging'
  }, {
    label: 'Pre-Prod',
    value: 'pre_prod'
  }, {
    label: 'Prod',
    value: 'prod'
  }
];

export const ASSET_CRITICALITY = [
  {
    label: 'Critical',
    value: 'critical'
  }, {
    label: 'High',
    value: 'high'
  }, {
    label: 'Medium',
    value: 'medium'
  }, {
    label: 'Low',
    value: 'low'
  }, {
    label: 'Not Critical',
    value: 'not_critical'
  }
];

export const DATA_SENSITIVITY = [
  {
    label: 'Public',
    value: 'public'
  }, {
    label: 'Internal Use',
    value: 'internal_use'
  }, {
    label: 'Confidential',
    value: 'confidential'
  }, {
    label: 'Secret',
    value: 'secret'
  }
];

export const NETWORK_EXPOSURE = [
  {
    label: 'Internet',
    value: 'internet'
  }, {
    label: 'Internal',
    value: 'internal'
  }
];

export const COMPLIANCE = [
  {
    label: 'Compliant',
    value: 'compliant'
  }, {
    label: 'Non-Compliant',
    value: 'non_compliant'
  }, {
    label: 'Not Applicable',
    value: 'not_applicable'
  }
];

export const METHOD =
  [
    {
      value: 'GET',
      label: 'GET'
    }, {
      value: 'POST',
      label: 'POST'
    }, {
      value: 'PUT',
      label: 'PUT'
    }, {
      value: 'DELETE',
      label: 'Low' // Assuming 'Low' is the intended label
    }, {
      value: 'PATCH',
      label: 'PATCH'
    }, {
      value: 'HEAD',
      label: 'HEAD'
    }, {
      value: 'OPTIONS',
      label: 'OPTIONS'
    }, {
      value: 'TRACE',
      label: 'TRACE'
    }
  ];

export const VULNERABILITY_CATEGORY = [
  {
    value: 'empty',
    label: 'Empty'
  }, {
    value: 'broken-access-control',
    label: 'Broken Access Control'
  }, {
    value: 'broken-authentication',
    label: 'Broken Authentication'
  }, {
    value: 'injection',
    label: 'Injection'
  }, {
    value: 'cryptographic-issues',
    label: 'Cryptographic Issues'
  }, {
    value: 'improper-input-validation',
    label: 'Improper Input Validation'
  }, {
    value: 'insecure-deserialization',
    label: 'Insecure Deserialization'
  }, {
    value: 'misconfiguration',
    label: 'Misconfiguration'
  }, {
    value: 'security-through-obscurity',
    label: 'Security Through Obscurity'
  }, {
    value: 'sensitive-data-exposure',
    label: 'Sensitive Data Exposure'
  }, {
    value: 'component-with-known-vulnerability',
    label: 'Component with Known Vulnerability'
  }, {
    value: 'insufficient-transport-layer',
    label: 'Insufficient Transport Layer'
  }
];

export const ATTACK_PHASE_OPTIONS = [
  {
    value: 'empty',
    label: 'Empty'
  }, {
    value: 'reconnaissance',
    label: 'Reconnaissance'
  }, {
    value: 'scanning',
    label: 'Scanning'
  }, {
    value: 'exploitation-gaining-access',
    label: 'Exploitation/Gaining Access'
  }, {
    value: 'maintaining-access',
    label: 'Maintaining Access'
  }, {
    value: 'covering-tracks',
    label: 'Covering Tracks'
  }
  // Add more options as needed
];

export const ENVIRONMENT_OPTIONS = [
  {
    value: 'empty',
    label: 'Empty'
  }, {
    value: 'local',
    label: 'Local'
  }, {
    value: 'dev',
    label: 'Dev'
  }, {
    value: 'integration',
    label: 'Integration'
  }, {
    value: 'test',
    label: 'Test'
  }, {
    value: 'staging',
    label: 'Staging'
  }, {
    value: 'production',
    label: 'Production'
  }
  // Add more options as needed
];

export const CATEGORY_ASSET: any = {
  OS: {
    sub_category: ['Windows', 'Linux', 'Mac'],
    product: {
      Windows: ['Windows 10', 'Windows 7', 'Windows 8'],
      Linux: ['Ubuntu', 'RedHat', 'Debian'],
      Mac: ['MacOS']
    }
  },
  Database: {
    sub_category: ['SQL', 'NoSQL'],
    product: {
      SQL: ['PostgreSQL', 'MySQL', 'MSSQL'],
      NoSQL: ['MongoDB', 'Cassandra', 'CouchDB']
    }
  },
  Middleware: {
    sub_category: ['Web Server', 'Application Server'],
    product: {
      'Web Server': ['Apache', 'Nginx', 'IIS'],
      'Application Server': ['Tomcat', 'JBoss', 'WebLogic']
    }
  }
};