import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../UI/header/Header';
import Breadcrumb from '../UI/breadcrumb/Breadcrumbs';
import Sidebar2 from '../UI/SideBar/SizeBar2';

const DefaultLayout = () => {
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem('sidebarCollapsed') !== null
      ? JSON.parse(localStorage.getItem('sidebarCollapsed')!)
      : true
  );

  useEffect(() => {
    localStorage.setItem('sidebarCollapsed', JSON.stringify(collapsed));
  }, [collapsed]);

  return (
    <html>
      <div className='flex h-screen min-w-full]'>
        <Sidebar2 />
        <div
          className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
        >
          {/* <!-- ===== Header Start ===== --> */}
          <Header
            sidebarOpen={collapsed}
            setSidebarOpen={
              (vl) => {
                setCollapsed(vl);
              }
            }
          />
          {/* <!-- ===== Main Content Start ===== --> */}
          <main>
            <div className="mx-auto p-4 md:p-6 2xl:p-10x">
              <Breadcrumb
                withLabel
                showBreadcrumb={false}
              />
              <Outlet />
            </div>
          </main>
        </div>

      </div>
    </html>
  );
};

export default DefaultLayout;
