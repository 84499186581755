import { createSelector } from 'reselect';

const selectReport = (state) => state.report;

export const selectCurrentItem = createSelector([selectReport], (report) => report.current);

export const selectListItems = createSelector([selectReport], (report) => report.list);

export const visibilityColumnsList = createSelector([selectReport], (report) => report.visibility);

export const selectItemById = (itemId) =>
  createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectReport], (report) => report.create);

export const selectUpdatedItem = createSelector([selectReport], (report) => report.update);

export const selectReadItem = createSelector([selectReport], (report) => report.read);

export const selectDeletedItem = createSelector([selectReport], (report) => report.delete);

export const selectSearchedItems = createSelector([selectReport], (report) => report.search);

export const reportItems = createSelector([selectReport], (report) => report.reportData);

export const reportList = createSelector([selectReport], (report) => report.reportList);