import { message } from 'antd';
import { API_BASE_URL, AUTH_BASE_URL } from '../config/serverApiConfig';
import errorHandler from '../request/errorHandler';
import successHandler from '../request/successHandler';
import { api } from './api';
import { AxiosError } from 'axios';
import { saveAs } from 'file-saver';

export const getAsset = async ({ page, items, sorting, globalFilter, filter }: { page: number; items: number, sorting: string, globalFilter: string, filter?: any }) => {
  try {

    let url = `${API_BASE_URL}assets/list?page=${page}&total=${items}`;
    if (sorting) {
      url += `&sorting=${JSON.stringify(sorting)}`;
    }
    if (globalFilter) {
      url += `&q=${globalFilter}`;
    }
    if (filter) {
      url += `&filter=${JSON.stringify(filter)}`;
    }
    const response = await api().get(url);

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );
    return response;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const createAsset = async (dataAsset: any) => {
  try {
    const response = await api().post(`${API_BASE_URL}asset/create`, dataAsset);
    const { status, data } = response;
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: true,
        notifyOnFailed: true
      }
    );
    return data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const getAssetIp = async (q?: string) => {
  try {
    let url = `${API_BASE_URL}assets/ip`;

    if (q) url += `?q=${q}`;

    const response = await api().get(url);

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: false
      }
    );
    return response;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const getAssetNames = async (q?: string, includeId?: string, projectId?: string) => {
  try {
    let url = `${API_BASE_URL}assets/name`;

    url += `?q=${q || ''}`;
    if (includeId) url += `&includeId=${includeId}`;
    if (projectId) url += `&projectId=${projectId}`;

    const response = await api().get(url);

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: false
      }
    );
    return response.data;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const getAssetName = async (q?: string) => {
  try {
    let url = `${API_BASE_URL}assetsbytags`;

    if (q) url += `?q=${q}`;

    const response = await api().get(url);

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: false
      }
    );
    return response;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const deleteAssets = async (vulnerabilityIds: string[]) => {
  try {
    const response = await api().post(`${API_BASE_URL}assets/delete`, {
      ids: vulnerabilityIds
    });

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: true,
        notifyOnFailed: true
      }
    );
    return response;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const deleteAllAssets = async () => {
  try {
    const response = await api().delete(`${API_BASE_URL}assets/delete-all`);

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: true,
        notifyOnFailed: true
      }
    );
    return response;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const getAssetDetail = async (assetId: string) => {
  try {
    const response = await api().get(`${API_BASE_URL}assets/${assetId}/details`);

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );
    return response;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const editAsset = async (assetId: string, data: any) => {
  try {
    delete data.id;
    delete data.tags;

    const response = await api().put(`${API_BASE_URL}assets/${assetId}/edit`, data);

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: true,
        notifyOnFailed: true
      }
    );
    return response;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const uploadAssetFile = async (formData: FormData, tags: string[]) => {
  try {
    let dataToSend = formData;

    dataToSend.append('json', JSON.stringify({ tags }));

    const response = await api().post(API_BASE_URL + 'assets/upload', dataToSend);

    successHandler(
      { response: response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );
    return response.data;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const exportFile = async (assetIds?: string[], format?: string) => {
  const loadingMessage = message.loading('Exporting...', 0); // Start loading

  try {
    let url = `${API_BASE_URL}assets/export`;
    let fileExtension = 'csv';
    let mimeType = 'text/csv;charset=utf-8;';

    if (format === 'excel') {
      url += '?format=excel';
      fileExtension = 'xlsx';
      mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }

    const response = await api().post(url, { ids: assetIds || [] }, {
      responseType: 'blob'
    });

    if (format === 'excel') {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'filename.xlsx');
      document.body.appendChild(fileLink);
      fileLink.click();

      fileLink.parentNode!.removeChild(fileLink);
      window.URL.revokeObjectURL(fileURL);
    } else {
      // Handle other formats
      const blob = new Blob([response.data], { type: mimeType });
      saveAs(blob, `exported_data.${fileExtension}`);
    }

    message.success(`${format?.toUpperCase()} Exported successfully`, 2.5);

    successHandler({
      response,
      status: response.status
    }, {
      notifyOnSuccess: false,
      notifyOnFailed: true
    });
  } catch (error) {
    console.error('Error exporting file:', error);
    message.error('Failed to export.', 2.5); // Show error message
  } finally {
    loadingMessage(); // Remove loading message
  }
};

export default {
  getAsset,
  getAssetIp,
  getAssetNames
};