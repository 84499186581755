import { Divider, Typography, Space } from 'antd';
import Card from 'antd/es/card/Card';
import ReactApexChart from 'react-apexcharts';
const { Text, Link } = Typography;

const VulnBySeverity = ({ data = [], stats = {} }: { data: any, stats: any }) => <Card>
  <div>
    <div className="flex w-full gap-4">
      <div className="w-full xl:w-8/12">
        <Typography.Title level={4}>Vulnerabilities</Typography.Title>
        <Typography.Paragraph>By Severity and status</Typography.Paragraph>
        <ReactApexChart
          series={data}
          options={{
            chart: {
              type: 'bar',
              height: 300,
              toolbar: {
                show: false
              }
            },
            colors: [
              '#FF9800', '#2196F3', '#4CAF50', '#E91E63', '#9E9E9E', '#6A0DAD'
            ],
            plotOptions: {
              bar: {
                borderRadius: 2,
                borderRadiusApplication: 'end',
                borderRadiusWhenStacked: 'last',
                hideZeroBarsWhenGrouped: true,
                barHeight: 55,
                horizontal: false,
                columnWidth: '80%'
              }
            },
            dataLabels: {
              enabled: false
            },
            legend: {
              show: true,
              position: 'bottom',
              horizontalAlign: 'left',
              markers: {
                shape: 'line'
              }
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: ['Critical', 'High', 'Medium', 'Low', 'Information'],
              title: {
                text: 'Severity'
              },
              axisTicks: {
                show: false
              }
            },
            yaxis: {
              title: {
                text: 'Number of Vulnerabilities'
              }
            },
            fill: {
              opacity: 60
            }
          }}
          type="bar"
          height={300}
        />
      </div>
      <div className="w-full xl:w-2/12">
        <Card
          className='h-full'
          title={
            <div className='flex items-center gap-1'>
              <div
                className='h-3 w-3 rounded-full bg-purple-500'
              /> Critical
            </div>
          }
          size='small'
        >
          <div className="flex flex-col h-full">
            <div>
              <Typography.Text
                type='secondary'
              >Total critical vulnerability</Typography.Text>
              <div className="font-bold text-3xl">{stats.critical ?? 0}</div>
            </div>
            <Divider className='mt-20' />
            <div>
              <Typography.Text
                type='secondary'
              >Unique critical vulnerability</Typography.Text>
              <div 
                className="font-bold text-3xl"
              >{stats.criticalUnique ?? 0}</div>
            </div>
          </div>
        </Card>
      </div>
      <div className="w-full xl:w-2/12">
        <Card
          className='h-full'
          title={
            <div className='flex items-center gap-1'>
              <div
                className='h-3 w-3 rounded-full bg-red-500'
              /> High
            </div>
          }
          size='small'
        >
          <div className="flex flex-col h-full">
            <div>
              <Typography.Text
                type='secondary'
              >Total high vulnerability</Typography.Text>
              <div className="font-bold text-3xl">{stats.high ?? 0}</div>
            </div>
            <Divider className='mt-20' />
            <div>
              <Typography.Text
                type='secondary'
              >Unique high vulnerability</Typography.Text>
              <div 
                className="font-bold text-3xl"
              >{stats.highUnique ?? 0}</div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </div>
</Card>;
export default VulnBySeverity;