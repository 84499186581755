import React, { useRef, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

// Define types for the data structure
interface TreeNode {
  name: string;
  value?: number;
  children?: TreeNode[];
}

interface TreemapProps {
  data: TreeNode;
}

const TreemapCPE: React.FC<TreemapProps> = ({ data = [] }: any) => (
  <div style={{ width: '100%', height: '100%' }}>
    <ReactApexChart
      series={data}
      options={{
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'left',
          markers: {
            shape: 'line'
          },
          showForSingleSeries: true,
          onItemClick: {
            toggleDataSeries: true
          }
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif'
          }
        },
        chart: {
          height: 350,
          type: 'treemap',
          toolbar: {
            show: false
          }
        },
        colors: ['#6F48EC', '#EF4444', '#F59E0B', '#10B981', '#3B82F6'],
        title: {
          align: 'center'
        },
        stroke: {
          lineCap: 'square',
          width: 4,
          dashArray: 0,
          curve: 'linestep'
        },
        plotOptions: {
          treemap: {
            enableShades: true,
            shadeIntensity: 0.5,
            reverseNegativeShade: false,
            distributed: false,
            useFillColorAsStroke: false,
            borderRadius: 0,
            dataLabels: {
              format: 'scale'
            }
          }
        }
      }
      }
      type='treemap'
      height={350}
    />
  </div>
);

export default TreemapCPE;
