import {
  FilePdfOutlined,
  FileWordOutlined,
  UserOutlined
} from '@ant-design/icons';
import {
  Collapse,
  CollapseProps,
  Divider,
  Radio,
  RadioChangeEvent,
  Typography
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reportItems } from '../../../../../redux/report/selectors';
import { report } from '../../../../../redux/report/actions';

const ReportSummary = () => {
  const dispatch = useDispatch();
  const reportData = useSelector(reportItems);

  const handleUpdateReportDirect = (keyFiled: string, newname?: any) =>
    dispatch<any>(report.updateReportField(keyFiled, newname));

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Summary',
      children: <div>
        <Typography.Paragraph>Summary of methods and procedures used.</Typography.Paragraph>
        <TextArea
          rows={4}
          title='Summary'
          onChange={(e) => handleUpdateReportDirect('summary', e.target.value)}
        />
      </div>
    }, {
      key: '2',
      label: 'Recommendations',
      children: <div>
        <Typography.Paragraph>Actions recommended to mitigate vulnerabilities found.</Typography.Paragraph>
        <TextArea
          rows={4}
          title='Recommendations'
          onChange={(e) => handleUpdateReportDirect('recommendations', e.target.value)}
        />
      </div>
    }, {
      key: '3',
      label: 'Scope',
      children: <div>
        <Typography.Paragraph>Scope of analysis taken into consideration. It refers to the set of assets that were evaluated.</Typography.Paragraph>
        <TextArea
          rows={4}
          title='Scope'
          onChange={(e) => handleUpdateReportDirect('scope', e.target.value)}
        />
      </div>
    }, {
      key: '4',
      label: 'Objectives',
      children: <div>
        <Typography.Paragraph>Objectives of the evaluation. Information expected to be obtained on the scope of analysis.</Typography.Paragraph>
        <TextArea
          rows={4}
          title='Objectives'
          onChange={(e) => handleUpdateReportDirect('objectives', e.target.value)}
        />
      </div>
    }, {
      key: '5',
      label: 'Conclusions',
      children: <div>
        <Typography.Paragraph>Findings on vulnerabilities and their characteristics.</Typography.Paragraph>
        <TextArea
          rows={4}
          title='Conclusions'
          onChange={(e) => handleUpdateReportDirect('conclusions', e.target.value)}
        />
      </div>
    }
  ];
  return <>
    <Typography.Paragraph>
      Select the format for the report
    </Typography.Paragraph>

    <Collapse
      accordion
      items={items}
      size="small"
    />
  </>;
};

export default ReportSummary;