import React from 'react';
import ReactApexChart from 'react-apexcharts';

const AttendanceBar = ({ data = [] }: any) => {

  const colors = ['#7B1FA2', '#D32F2F', '#F57C00', '#388E3C'];

  return (
    <div
      className="flex justify-start flex-col px-0 pt-5"
    > {/* Removed extra padding for better fit */}
      <ReactApexChart
        options={{
          chart: {
            type: 'bar',
            stacked: true,
            toolbar: { show: false },  // Disable toolbar
            zoom: { enabled: false },  // Disable zoom
            sparkline: { enabled: true } // Removes extra margins
          },
          plotOptions: {
            bar: {
              borderRadiusApplication: 'around',
              borderRadiusWhenStacked: 'all',
              horizontal: true,
              barHeight: '100%',  // Ensure bars occupy full height
              borderRadius: 5
            }
          },
          xaxis: {
            labels: { show: false },  // Hide x-axis labels
            axisTicks: { show: false }, // Hide axis ticks
            axisBorder: { show: false } // Hide axis border
          },
          yaxis: {
            show: false // Hide y-axis
          },
          grid: {
            show: false, // Disable grid lines
            padding: {
              left: 10,
              right: 10,
              top: 0,
              bottom: 0 // Slight padding at bottom for better legend positioning
            }
          },
          dataLabels: {
            enabled: false // Disable data labels to keep the chart clean
          },
          tooltip: {
            enabled: true // Enable tooltips for interactivity
          },
          legend: {
            show: false,  // Show the legend
            position: 'bottom',
            horizontalAlign: 'left',
            markers: {
              shape: 'circle'
            },
            offsetY: 0,  // Adjust space between legend and chart
            itemMargin: {
              horizontal: 5,  // Margin between legend items
              vertical: 0  // Margin between rows of legend items
            },
            labels: {
              colors: '#333'  // Legend text color
            }
          },
          fill: {
            opacity: 1  // Full color opacity for solid bars
          },
          colors: colors
        }}
        series={data}
        type="bar"
        height={10}
      />
      <div
        className="legend"
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          marginTop: '10px',
          fontSize: '12px'
        }}
      >
        <div className="flex flex-wrap gap-4">
          {data.map((e: any, i: number) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span
                style={{
                  backgroundColor: colors[i],
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  marginRight: '5px'
                }}
              ></span>
              {e.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AttendanceBar;
