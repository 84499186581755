import React, { useEffect, useState } from 'react';
import Card from 'antd/es/card/Card';
import { Tag, Tooltip, Typography } from 'antd';
import Title from 'antd/es/skeleton/Title';

const RiskHeatMap = ({ data }: any) => {
  console.log('------- data', data);
  const [tableRows, setTableRows] = useState([
    {
      label: 'High',
      values: [0, 0, 0, 0],
      colors: ['bg-red-400', 'bg-red-500', 'bg-purple-400', 'bg-purple-500']
    }, {
      label: 'Medium',
      values: [0, 0, 0, 0],
      colors: ['bg-yellow-400 !text-black', 'bg-red-300', 'bg-red-400', 'bg-purple-400']
    }, {
      label: 'Low',
      values: [0, 0, 0, 0],
      colors: ['bg-green-400', 'bg-yellow-300 !text-black', 'bg-yellow-400 !text-black', 'bg-red-300']
    }, {
      label: 'Very Low',
      values: [0, 0, 0, 0],
      colors: ['bg-green-300', 'bg-green-400', 'bg-yellow-300 !text-black', 'bg-yellow-400 !text-black']
    }
  ]);

  useEffect(() => {
    if (data) {
      const updatedRows = tableRows.map((row, rowIndex) => ({
        ...row,
        values: data[rowIndex].values
      }));
      setTableRows(updatedRows);
    }
  }, [data]);

  const tableHeaders = ['Low', 'Medium', 'High', 'Critical'];

  const legends = [
    {
      title: 'Critical',
      color: 'bg-purple-500'
    }, {
      title: 'High',
      color: 'bg-red-500'
    }, {
      title: 'Medium',
      color: 'bg-yellow-400 !text-black'
    }, {
      title: 'Low',
      color: 'bg-green-500'
    }
  ];

  return (
    <Card
      styles={{
        body: {
          padding: '20px 10px'
        }
      }}
    >
      <div className='mx-5'>
        <Typography.Title level={4}>Risk HeatMap</Typography.Title>
        <Typography.Paragraph>Heat Map of risk of asset by impact ...</Typography.Paragraph>
      </div>
      <div className="overflow-auto my-4 mt-10 px-10">
        <table
          className="table-auto border-collapse border-gray-500 w-full"
        >
          <tbody>
            {/* Dynamic rows */}
            {tableRows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <th
                  className="p-2 bg-gray-500 text-start text-[#a6a8ac] font-bold text-sm w-[50px] whitespace-nowrap"
                >
                  {row.label}
                </th>
                <td
                  colSpan={row.values.length}
                >  {/* Make the cell span the full width */}
                  <div
                    className="grid grid-cols-1 md:grid-cols-4 gap-1 w-full"
                  >
                    {row.values.map((value, colIndex) => (
                      <div
                        key={colIndex}
                        className={`hover:opacity-90  flex justify-center items-center text-center rounded-sm h-[35px] text-white w-full font-bold ${row.colors[colIndex]}`}
                        style={{
                          backgroundColor: row.colors[colIndex]
                        }}
                      >
                        <Tooltip title={value}>
                          <span>{value}</span> {/* Display value in tooltip */}
                        </Tooltip>
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
            ))}

            {/* Headers row */}
            <tr>
              <th className="p-2 bg-gray-500 text-black"></th>
              {tableHeaders.map((header, index) => (
                <th
                  key={index}
                  className="p-2 bg-gray-500 text-[#a6a8ac] font-bold text-sm"
                >
                  {header}
                </th>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
      <div className='flex justify-end gap-1'>
        {legends.map((legend, index) => (
          <div className='flex flex-col items-center gap-1' key={index}>
            <span
              className='text-[#a6a8ac] font-bold text-sm'
            >{legend.title}</span>
            <div
              className={`${legend.color} h-[10px] w-[50px] rounded-sm`}
            ></div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default RiskHeatMap;
