import { AddUserProps } from '../components/UI/tab/types';
import { API_BASE_URL, AUTH_BASE_URL } from '../config/serverApiConfig';
import errorHandler from '../request/errorHandler';
import successHandler from '../request/successHandler';
import { api } from './api';
import axios, { AxiosError } from 'axios';

export const generateReport = async (reportData: any) => {
  try {
    const data = { ...reportData };
    delete data.assetList;
    delete data.vuln_filter;

    const formData = new FormData();
    formData.append('report_data', JSON.stringify(data));

    // Make the API request
    const response = await api().post(`${API_BASE_URL}report/generate-docx`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'blob'
    });

    // Parse the Blob to JSON
    const responseBlob = response.data;
    const responseJson = await new Response(responseBlob).json();

    downloadDoc(responseJson.file_url, 'docx');

    // Optional: Display success message
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );

    return responseJson.file_url;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const generateReport2 = async () => {
  try {
    const formData = new FormData();

    // Make the API request
    const response = await api().get(`${API_BASE_URL}report/generate-t`, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'blob' // To handle the document as a file download
    });

    // Handle success (for example, triggering a file download)
    const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', 'vulnerability_report.docx'); // Set default download name
    document.body.appendChild(link);
    link.click();

    // Optional success handler (notifications, etc.)
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: true,
        notifyOnFailed: true
      }
    );

    return response;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const getVulneStatsByProject = async (projectId: string, filter?: any) => {
  try {
    let url = `${API_BASE_URL}report/get-vuln-stats-by-project`;

    if (projectId) {
      url += '?project=' + projectId;
    }
    if (filter) {
      url += `&filter=${JSON.stringify(filter)}`;
    }
    const response = await api().get(url);

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );
    return response;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const reportList = async (page: number, pageSize: number) => {
  try {
    // Construct the API URL with pagination parameters
    const url = `${API_BASE_URL}report/list?page=${page}&page_size=${pageSize}`;

    // Make the API request
    const response = await api().get(url);

    // Handle success response, you may choose to notify user on success or failure
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );

    // Return the paginated data
    return response.data;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const downloadDoc = async (docPath: string, type?: string) => {
  try {
    const url = docPath.replace('reports/', '');
    const response = await axios.get(`${API_BASE_URL}report/download-doc?doc_path=${url}&type=${type}`, {
      responseType: 'blob'
    });

    // Create a download link and trigger the download
    const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', docPath.split('/').pop() || 'report.docx'); // Extracts filename or uses a default
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up the link element
    window.URL.revokeObjectURL(fileUrl); // Free memory

    // Success notification
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: true,
        notifyOnFailed: true
      }
    );

    return response;
  } catch (error) {
    return errorHandler(error);
  }
};

export default {
  generateReport,
  generateReport2,
  getVulneStatsByProject,
  reportList,
  downloadDoc
};
