import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Breadcrumb as AntBreadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import routes from '../../../routes';
import LogoBlue from '../../../assets/image/blue.png';

interface BreadcrumbProps {
  withLabel?: boolean;
  showBreadcrumb?: boolean;
}

const Breadcrumb: React.FC<BreadcrumbProps> = React.memo(({ withLabel = false, showBreadcrumb = true }) => {
  const { pathname } = useLocation();
  const pathSegments = pathname.split('/').filter(Boolean);

  // Helper function to find route details for each segment
  const findRouteTitle = (segment: string, fullPath: string) => {
    const matchedRoute = routes.find(route => {
      const [basePath] = route.path.split('/:');
      return route.path === fullPath || (fullPath.startsWith(basePath) && route.path.includes('/:'));
    });
    return {
      title: matchedRoute?.title || segment
    };
  };

  // Determine the title for the last segment or use "Home" for the root path
  const lastSegment = pathSegments.length > 0 ? pathSegments[pathSegments.length - 1] : 'Home';
  const lastSegmentRoute = pathSegments.length > 0 ? findRouteTitle(lastSegment, pathname) : { 
    title: '' 
  };

  // Generate breadcrumb items
  const breadcrumbItems = [
    {
      href: '/',
      title: (
        <Link to="/">
          <img 
            src={LogoBlue} 
            alt="Home" 
            className="p-[1px]" 
            width={23} 
          />
        </Link>
      )
    }, ...pathSegments.map((segment, index) => {
      const routeTo = `/${pathSegments.slice(0, index + 1).join('/')}`;
      const matchedRoute = findRouteTitle(segment, routeTo);
      const isLast = index === pathSegments.length - 1;

      return {
        href: !isLast ? routeTo : undefined,
        title: !isLast ? (
          <Link to={routeTo}>{matchedRoute.title}</Link>
        ) : (
          matchedRoute.title
        )
      };
    })
  ];

  return (
    <div
      className={`mb-2 ${withLabel ? 'flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between' : ''}`}
    >
      {withLabel && (
        <h2 className="text-title-md font-semibold text-black dark:text-white">
          {lastSegmentRoute.title}
        </h2>
      )}
      {showBreadcrumb && (
        <AntBreadcrumb items={breadcrumbItems} className="mt-2" />
      )}
    </div>
  );
});

export default Breadcrumb;
