import { useEffect, useState } from 'react';
import Title from 'antd/es/typography/Title';
import { useDispatch, useSelector } from 'react-redux';
import { reportItems } from '../../../../../redux/report/selectors';
import { report } from '../../../../../redux/report/actions';
import {
  Radio,
  Select,
  Space,
  Form as AntdForm,
  Checkbox,
  GetProp,
  Card,
  Typography,
  Alert
} from 'antd';
import { VULNERABILITY_STATUS } from '../../../../../config/enum';
import projectService from '../../../../../service/project.service';
import AssetIconType from '../../../../utils/asset/AssetTypeIcon';

const ReportVulnerabilityData = () => {

  const dispatch = useDispatch();
  const reportData = useSelector(reportItems);

  const handleUpdateReportDirect = (keyFiled: string, newname?: any) =>
    dispatch<any>(report.updateReportField(keyFiled, newname, true));

  const onChangeCheckFilter = (fieldName: any, checkedValues: any) => {
    handleUpdateReportDirect(fieldName, checkedValues);
  };

  const [projects, setprojects] = useState([]);
  const [loadingPorject, setloadingPorject] = useState(false);
  useEffect(() => {
    const getProjects = async () => {
      setloadingPorject(true);
      const projects = await projectService.getListName();
      setprojects(projects.projects.map((v: any) => ({
        label: v.name + ` (${v.vulns_count})`,
        value: v.id
      })));
      setloadingPorject(false);
    };
    getProjects();
  }, []);

  return <>
    <Alert
      message={`Report generated with ${reportData.total_vulnerabilities || 0} vulnerabilities and ${reportData.total_assets || 0} assets.`}
      type="info"
      showIcon
    />
    <AntdForm.Item
      className='w-full'
      layout="vertical"
      required
      label="Project"
    >
      <Select
        style={{ width: '100%' }}
        placeholder="Please select projects..."
        onChange={(value) => handleUpdateReportDirect('project', value!)}
        allowClear
        value={reportData.project}
        loading={loadingPorject}
        disabled={loadingPorject}
        options={projects}
        optionLabelProp="label"
      />
    </AntdForm.Item>

    <div className='mt-5'>
      <Card
        title="Vulnerabilities"
        type='inner'
        size="small"
      >
        <div
          className="bg-white"
        >
          <div
          >
            <div className='text-gray-600'>
              Filtered list of detected vulnerabilities that will be shown in the report
            </div>
            <div>
              <Title
                level={5}
              >What do you want to inculed?</Title>
              <Radio.Group
                onChange={(e) => handleUpdateReportDirect('vuln_filter', e.target.value)}
                value={reportData.vuln_filter}
                disabled={reportData.project ? false : true}
              >
                <Space
                  direction="vertical"
                >
                  <Radio
                    value={1}
                  >All Vulnerabilities</Radio>
                  <Radio
                    value={2}
                  >Filter by fields</Radio>
                </Space>
              </Radio.Group>

              <AntdForm
                labelCol={{
                  span: 4
                }}
                wrapperCol={{
                  span: 14
                }}
                layout="horizontal"
                disabled={reportData.vuln_filter !== 2}
                style={{
                  maxWidth: 600
                }}
              >

                <div
                  className='my-5 flex flex-col'
                >
                  Filter by assets
                  <Select
                    allowClear
                    mode="multiple"
                    // searchValue={assetSearch}
                    // onSearch={(v) => {
                    //   setAssetSearch(v);
                    //   const ids = values.assets.map((v: any) => v.value);
                    //   getAssets(v, ids);
                    // }}
                    optionRender={(props) =>
                      <div className='flex items-center'>
                        <div className="w-10 mr-2">
                          <AssetIconType
                            type={props.data.type!}
                            size='sm'
                          />
                        </div>
                        <div>{props.label}</div>
                      </div>
                    }
                    onChange={(v) => handleUpdateReportDirect('assets', v)}
                    value={reportData.assets}
                    style={{ width: '100%' }}
                    placeholder="Type and add asset..."
                    filterOption={false}
                    defaultActiveFirstOption={false}
                    showSearch
                    options={reportData.assetList.map((v: any) => ({
                      label: v.name,
                      value: v.id,
                      type: v.type
                    }))}
                  />
                </div>

                <Typography.Title
                  className='mt-5'
                  level={5}
                >Status</Typography.Title>
                <Checkbox.Group
                  options={options}
                  className='gap-4 flex'
                  onChange={(e) => handleUpdateReportDirect('status', e)}
                  value={reportData.status}
                />
                <Typography.Title
                  className='mt-5'
                  level={5}
                >Severity</Typography.Title>
                <Checkbox.Group
                  options={optionsSeverity}
                  className='gap-4 flex'
                  onChange={(e) => handleUpdateReportDirect('severity', e)}
                  value={reportData.severity}
                />
                <Typography.Title
                  className='mt-5'
                  level={5}
                >Confirmed</Typography.Title>
                <Checkbox.Group
                  options={optionsConfirmed}
                  className='gap-4 flex'
                  onChange={(e) => handleUpdateReportDirect('confirmed', e)}
                  value={reportData.confirmed}
                />
                <Typography.Title
                  className='mt-5'
                  level={5}
                >Include this tags</Typography.Title>
                <Checkbox.Group
                  options={optionTags}
                  className='gap-4 grid grid-cols-3'
                  onChange={(e) => onChangeCheckFilter('tags', e)}
                  value={reportData.tags}
                />
              </AntdForm>
            </div>
          </div>
        </div>
      </Card>
    </div>
  </>;
};

const options = VULNERABILITY_STATUS;

const optionsSeverity = [{
  label: 'Critical',
  value: 'critical'
}, {
  label: 'High',
  value: 'high'
}, {
  label: 'Medium',
  value: 'medium'
}, {
  label: 'Low',
  value: 'low'
}, {
  label: 'Informational',
  value: 'informational'
}];

const optionsConfirmed = [{
  label: 'Confirmed',
  value: 'confirmed'
}, {
  label: 'Unconfirmed',
  value: 'unconfirmed'
}];

const optionTags = [{
  label: 'PATCH-AVAILABLE',
  value: 'PATCH-AVAILABLE'
}, {
  label: 'EXPLOIT-AVAILABLE',
  value: 'EXPLOIT-AVAILABLE'
}];

export default ReportVulnerabilityData;