import * as actionTypes from './types';

const updateListField = (listName, index, field, value, currentData) => {
  // Create a copy of the current list or initialize it if empty
  const updatedList = currentData[listName] ? [...currentData[listName]] : [];

  // Ensure the list has an object at the specified index
  updatedList[index] = updatedList[index] || {};

  // Update the specific field in the object at the index
  updatedList[index] = { ...updatedList[index], [field]: value };

  return { [listName]: updatedList };
};

const INITIAL_KEY_STATE = {
  result: null
};
const INITIAL_STATE = {
  current: {
    result: null
  },
  reportData: {
    assetList: [],
    type: 'docx',
    name: '',
    client_name: '',
    project: '',
    vuln_filter: 1
  },
  reportList: {
    reports: [],
    loading: false,
    error: null,
    currentPage: 1,
    totalPages: 1,
    hasMore: true,
    total_records: 0
  },
  create: INITIAL_KEY_STATE,
  update: INITIAL_KEY_STATE,
  delete: INITIAL_KEY_STATE,
  read: INITIAL_KEY_STATE
};

const reportReducer = (state = INITIAL_STATE, action) => {
  const { payload, keyState } = action;
  switch (action.type) {
    case actionTypes.RESET_STATE:
      return INITIAL_STATE;
    case actionTypes.CURRENT_ITEM:
      return {
        ...state,
        current: {
          result: payload
        }
      };
    case actionTypes.REQUEST_LOADING:
      return {
        ...state,
        [keyState]: {
          ...state[keyState],
          isLoading: true
        }
      };
    case actionTypes.REQUEST_FAILED:
      return {
        ...state,
        [keyState]: {
          ...state[keyState],
          isLoading: false,
          isSuccess: false
        }
      };
    case actionTypes.REQUEST_SUCCESS:
      return {
        ...state,
        [keyState]: {
          result: payload,
          isLoading: false,
          isSuccess: true
        }
      };
    case actionTypes.CURRENT_ACTION:
      return {
        ...state,
        [keyState]: {
          ...INITIAL_KEY_STATE,
          current: payload
        }
      };
    case actionTypes.RESET_ACTION:
      return {
        ...state,
        [keyState]: {
          ...INITIAL_STATE[keyState]
        }
      };

    case actionTypes.UPDATE_VISIBILITY:
      return {
        ...state,
        visibility: {
          ...state.visibility,
          ...action.payload // Merge new visibility flags into the current state
        }
      };
    case actionTypes.UPDATE_REPORT_DATA:
      return {
        ...state,
        reportData: {
          ...state.reportData,
          ...payload
        }
      };
    case actionTypes.UPDATE_REPORT_DATA_LIST:
      {
        const { key, value, index, field } = action.payload;

        return {
          ...state,
          reportData: {
            ...state.reportData,
            ...updateListField(key, index, field, value, state.reportData)
          }
        };
      }
      ;
    case actionTypes.REMOVE_LIST_ITEM:
      {
        const { key, index } = action.payload;
        const updatedList = state.reportData[key] ? [...state.reportData[key]] : [];
        updatedList.splice(index, 1); // Remove the item at the specified index

        return {
          ...state,
          reportData: {
            ...state.reportData,
            [key]: updatedList
          }
        };
      }
      ;
    case actionTypes.ADD_LIST_ITEM:
      {
        const { key } = action.payload;
        const updatedList = state.reportData[key] ? [...state.reportData[key]] : [];
        updatedList.push({}); // Add an empty object for a new entry

        return {
          ...state,
          reportData: {
            ...state.reportData,
            [key]: updatedList
          }
        };
      }
      ;
    case actionTypes.UPDATE_REPORT_DATA_OBJECT:
      {
        const { objectName, field, value } = action.payload;

        return {
          ...state,
          reportData: {
            ...state.reportData,
            [objectName]: {
              ...state.reportData[objectName],
              [field]: value
            }
          }
        };
      }
      ;
    case actionTypes.FETCH_REPORTS_REQUEST:
      return {
        ...state,
        reportList: {
          ...state.reportList,
          loading: true
        }
      };
    case actionTypes.FETCH_REPORTS_SUCCESS:
      const { reports, total_pages, total_records } = action.payload;
      return {
        ...state,
        reportList: {
          loading: false,
          reports: [...state.reportList.reports, ...reports],
          currentPage: state.reportList.currentPage + 1,
          totalPages: total_pages,
          hasMore: state.reportList.currentPage < total_pages,
          total_records: total_records
        }
      };
    case actionTypes.FETCH_REPORTS_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

export default reportReducer;
