import { Formik, Form } from 'formik';
import { useEffect, useState } from 'react';
import {
  Modal,
  Button as AntButton,
  Select,
  Tabs,
  Input,
  Form as AntdForm,
  notification,
  Alert,
  Typography,
  Table
} from 'antd';
import * as Yup from 'yup';
import {
  updateUser,
  getUserDetails,
  getRoleList
} from '../../../../service/user.service';
const { Title, Paragraph } = Typography;

interface EditUserProps {
    isVisible: boolean;
    onClose: () => void;
    onUserUpdated: () => void;
    userId: string;  // Pass the user ID for fetching details
}

export default function EditUser({ isVisible, onClose, onUserUpdated, userId }: EditUserProps) {
  const [initialValues, setInitialValues] = useState({
    role: '',
    email: '',
    firstName: '',
    lastName: ''
  });

  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [loadingUserDetails, setLoadingUserDetails] = useState(true);
  const [isError, setIsError] = useState('');
  const [sessions, setSessions] = useState([]);

  const validationSchema = Yup.object().shape({
    role: Yup.string().required('Role is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required')
  });

  useEffect(() => {
    if (userId) {
      fetchUserDetails(userId);
      fetchRoles();
    }
  }, [userId]);

  const fetchUserDetails = async (id: string) => {
    setLoadingUserDetails(true);
    setIsError(''); // Reset error message
    try {
      const userData = await getUserDetails(id);
      setInitialValues({
        email: userData.email,
        firstName: userData.first_name,
        lastName: userData.last_name,
        role: userData.role
      });
      setSessions(userData.sessions);
    } catch (error) {
      console.error('Error fetching user details:', error);
      setIsError('Error fetching user details');
      notification.error({
        message: 'Error',
        description: 'There was an error fetching the user details. Please try again later.'
      });
    } finally {
      setLoadingUserDetails(false);
    }
  };

  const fetchRoles = async () => {
    setLoadingRoles(true);
    try {
      const roles = await getRoleList();
      setRoles(
        roles.map((v: any) => ({
          label: v.name,
          value: v.name,
          id: v.name
        }))
      );
    } catch (error) {
      console.error('Error fetching roles:', error);
      notification.error({
        message: 'Error',
        description: 'There was an error fetching the roles. Please try again later.'
      });
    } finally {
      setLoadingRoles(false);
    }
  };

  const handleClose = () => {
    setInitialValues({
      role: '',
      email: '',
      firstName: '',
      lastName: ''
    });
    setIsError(''); 
    onClose();
  };

  return (
    <Modal
      destroyOnClose
      title="Edit User"
      visible={isVisible}
      onCancel={handleClose}
      footer={null}
    >
      {loadingUserDetails ? (
        <p>Loading...</p>
      ) : (
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            setIsLoading(true);
            try {
              await updateUser(userId, values);  // Ensure updateUser accepts the userId and values
              notification.success({
                message: 'Success',
                description: 'User details updated successfully.'
              });
              onUserUpdated();  // Callback to notify the parent component that a user was updated
              handleClose();  // Close the modal and reset form data
            } catch (error) {
              console.error('Error updating user:', error);
              notification.error({
                message: 'Error',
                description: 'There was an error updating the user. Please try again later.'
              });
            } finally {
              actions.setSubmitting(false);
              setIsLoading(false);
            }
          }}
        >
          {({ isSubmitting, isValid, dirty, handleSubmit, setFieldValue, values, touched, errors }) => (
            <Form>
              <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="General" key="1">

                  {isError ?
                    <Alert
                      message="Error"
                      description={isError}
                      type="error"
                      showIcon
                    /> :
                    <div>

                      <AntdForm.Item
                        layout='vertical'
                        required
                        label="E-mail"
                        validateStatus={touched.email && errors.email ? 'error' : ''}
                        help={touched.email && errors.email ? errors.email : ''}
                      >
                        <Input
                          name="email"
                          placeholder="Email"
                          onChange={(e) => setFieldValue('email', e.target.value)}
                          value={values.email}
                          disabled
                        />
                      </AntdForm.Item>

                      <AntdForm.Item
                        layout='vertical'
                        required
                        label="Role"
                        validateStatus={touched.role && errors.role ? 'error' : ''}
                        help={touched.role && errors.role ? errors.role : ''}
                      >
                        <Select
                          loading={loadingRoles}
                          placeholder="Please select roles..."
                          // options={roles}
                          options={[{
                            label: 'System Admin',
                            value: 'SYS_ADMIN'
                          }]}
                          onChange={(value) => setFieldValue('role', value)}
                          value={values.role}
                        />
                      </AntdForm.Item>

                      <div 
                        className="grid grid-cols-2 gap-5"
                      >
                        <AntdForm.Item
                          layout='vertical'
                          required
                          label="First Name"
                          validateStatus={touched.firstName && errors.firstName ? 'error' : ''}
                          help={touched.firstName && errors.firstName ? errors.firstName : ''}
                        >
                          <Input
                            name="firstName"
                            placeholder="First Name"
                            onChange={(e) => setFieldValue('firstName', e.target.value)}
                            value={values.firstName}
                          />
                        </AntdForm.Item>

                        <AntdForm.Item
                          layout='vertical'
                          required
                          label="Last Name"
                          validateStatus={touched.lastName && errors.lastName ? 'error' : ''}
                          help={touched.lastName && errors.lastName ? errors.lastName : ''}
                        >
                          <Input
                            name="lastName"
                            placeholder="Last Name"
                            onChange={(e) => setFieldValue('lastName', e.target.value)}
                            value={values.lastName}
                          />
                        </AntdForm.Item>
                      </div>
                    </div>  
                  }
                  <div 
                    className="flex justify-end gap-2 mt-4"
                  >
                    <AntButton
                      type="default"
                      onClick={handleClose}
                    >
                                            Cancel
                    </AntButton>
                    <AntButton
                      type="primary"
                      loading={isLoading}
                      disabled={!isValid || !dirty || isSubmitting}
                      onClick={() => handleSubmit()}
                    >
                                            Update User
                    </AntButton>
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Session" key="2">
                  <div className="min-h-[300px]">
                    <Title level={5}>{initialValues.email}</Title>
                    <Paragraph>View your active sessions.</Paragraph>
                    <Table
                      pagination={false}
                      columns={[
                        {
                          title: 'Started',
                          dataIndex: 'started'
                        }, {
                          title: 'Last access',
                          dataIndex: 'lastAccess'
                        }, {
                          title: 'Ip address',
                          dataIndex: 'ipAddress'
                        }
                      ]} 
                      dataSource={sessions} 
                      size="small" 
                    />
                  </div>
                </Tabs.TabPane>
              </Tabs>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
}
