import { AddVulnsManualProps } from '../components/UI/tab/types';
import { API_BASE_URL } from '../config/serverApiConfig';
import errorHandler from '../request/errorHandler';
import successHandler from '../request/successHandler';
import { api } from './api';
import { AxiosError } from 'axios';
import { message, notification } from 'antd';
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux';
import { selectListItems } from '../redux/vulnerability/selectors';

export const getVulnerabilities = async ({ page, items, sorting, globalFilter, filter, report = false }: { page: number; items: number; sorting: any; globalFilter: string, filter?: any, report?: boolean }) => {
  try {
    let url = `${API_BASE_URL}vulns/list?page=${page}&total=${items}`;
    if (sorting) {
      url += `&sorting=${JSON.stringify(sorting)}`;
    }
    if (globalFilter) {
      url += `&q=${globalFilter}`;
    }
    if (filter) {
      url += `&filter=${JSON.stringify(filter)}`;
    }
    url += `&report=${report}`;

    const response = await api().get(url);

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );
    return response;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const getVulnerabilitieByAssetId = async (assetId: string) => {
  try {
    let url = `${API_BASE_URL}vulnsbyasset/${assetId}`;
    const response = await api().get(url);

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );
    return response;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const uploadVulnsFile = async (formData: FormData) => {
  try {
    const response = await api().post(API_BASE_URL + 'vulnerability/upload', formData);

    successHandler(
      { response: response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );
    return response.data;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const getVulnerabilityDetail = async ({ id }: { id: string }) => {
  try {
    const response = await api().get(`${API_BASE_URL}vulns/${id}/details`);
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );
    return response;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const getVulnerabilityEvidence = async (id: number) => {
  try {
    const response = await api().get(`${API_BASE_URL}vulns/${id}/evidence`);
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: true
      }
    );
    return response.data;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};
export const addVulnerability = async (vulnerabilityData: AddVulnsManualProps, files: File[] | null) => {
  try {
    const formData = new FormData();

    const resolution = vulnerabilityData.resolution?.toLowerCase();
    if (resolution?.includes('patch') || resolution?.includes('update') || resolution?.includes('upgrade')) {
      vulnerabilityData.tags.push('patch-available');
    }
    const {
      accountability, availability, integrity, confidentiality,
      webVulnerability, evidenceFiles, ...dataToSend
    } = vulnerabilityData;

    formData.append('json', JSON.stringify(dataToSend));

    // Append files
    if (files) {
      files.forEach(file => {
        formData.append('file', file, file.name);
      });
    }

    let response = await api().post(`${API_BASE_URL}vulnerability/create`, formData);

    const vulLenght = response.data.vulns.length;
    if (vulLenght > 0) {
      response.data.message = `${vulLenght} Vulnerability created`;
      successHandler(
        { response, status: response.status },
        {
          notifyOnSuccess: true,
          notifyOnFailed: true
        }
      );
      return response.data;
    } else {
      notification.info({
        message: 'Vulnerability not created',
        description: '0 vulnerabilities created. Please check the data and try again'
      });
      return {
        success: false,
        data: response.data,
        result: response.data,
        message: 'Vulnerability not created'
      };
    }

  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

interface UPDATE_VULNERABITLIY_PROPS {
  vulnerabilityData: any;
  files?: File[] | null;
  vulnerabilityId: string;
  notifyOnSuccess?: boolean;
}

export async function updateVulnerability({
  vulnerabilityData,
  files,
  vulnerabilityId,
  notifyOnSuccess = true
}: UPDATE_VULNERABITLIY_PROPS): Promise<any> {
  try {
    const formData = new FormData();

    // Append the vulnerability data as a JSON string under the 'json' key
    formData.append('json', JSON.stringify(vulnerabilityData));

    // Append files if they exist
    if (files) {
      files.forEach(file => {
        formData.append('file', file, file.name);
      });
    }

    // Perform the PUT request to the API endpoint for updating the vulnerability
    const response = await api().put(`${API_BASE_URL}vulns/${vulnerabilityId}/edit`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: notifyOnSuccess,
        notifyOnFailed: true
      }
    );
    return response;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
}
;

export async function uploadImage({
  files,
  vulnerabilityId,
  notifyOnSuccess = true
}: UPDATE_VULNERABITLIY_PROPS): Promise<any> {
  try {
    const formData = new FormData();

    // Append the vulnerability ID
    formData.append('vul_id', String(vulnerabilityId));

    // Append files if they exist
    if (files) {
      files.forEach(file => {
        formData.append('file', file, file.name);
      });
    }

    // Perform the POST request to the API endpoint for uploading the evidence
    const response = await api().post(`${API_BASE_URL}vulns/evidence/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: notifyOnSuccess,
        notifyOnFailed: true
      }
    );

    return response;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
}

export const fetchVulnerabilitiesByName = async (name: string) => {
  try {
    let url = `${API_BASE_URL}vulnsbyname?name=${name}&total=100`;

    const response = await api().get(url);

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: false,
        notifyOnFailed: false
      }
    );
    return response;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const deleteVulnerabilities = async (vulnerabilityIds: string[]) => {
  try {
    const response = await api().post(`${API_BASE_URL}vulns/delete`, {
      ids: vulnerabilityIds
    });

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: true,
        notifyOnFailed: true
      }
    );
    return response;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const deleteEvidence = async (vulId: any ,evidenceId: any) => {
  try {
    const response = await api().delete(`${API_BASE_URL}vulns/${vulId}/evidence/${evidenceId}`);

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: true,
        notifyOnFailed: true
      }
    );
    return response.data;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const exportFile = async (vulnerabilityIds?: string[], format?: string) => {
  const loadingMessage = message.loading('Exporting...', 0); // Start loading

  try {
    let url = `${API_BASE_URL}vulns/export`;
    let fileExtension = 'csv';
    let mimeType = 'text/csv;charset=utf-8;';

    if (format === 'excel') {
      url += '?format=excel';
      fileExtension = 'xlsx';
      mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }

    const response = await api().post(url, { ids: vulnerabilityIds || [] }, {
      responseType: 'blob'
    });

    if (format === 'excel') {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'filename.xlsx');
      document.body.appendChild(fileLink);
      fileLink.click();

      fileLink.parentNode!.removeChild(fileLink);
      window.URL.revokeObjectURL(fileURL);
    } else {
      // Handle other formats
      const blob = new Blob([response.data], { type: mimeType });
      saveAs(blob, `exported_data.${fileExtension}`);
    }

    message.success(`${format?.toUpperCase()} Exported successfully`, 2.5);

    successHandler({
      response,
      status: response.status
    }, {
      notifyOnSuccess: false,
      notifyOnFailed: true
    });
  } catch (error) {
    console.error('Error exporting file:', error);
    message.error('Failed to export.', 2.5); // Show error message
  } finally {
    loadingMessage(); // Remove loading message
  }
};

export const deleteAllVulnerabilities = async () => {
  try {
    const response = await api().delete(`${API_BASE_URL}vulns/delete-all`);

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: true,
        notifyOnFailed: true
      }
    );
    return response;
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};

export const bulkUpdateVulnerabilities = async (vulnerabilityIds: string[], updateData: { [key: string]: any }, filter = {}, search?: string) => {
  try {
    const response = await api().put(`${API_BASE_URL}vulns/bulk-update`, {
      ids: vulnerabilityIds,
      filter: vulnerabilityIds.length === 0 ? {} : filter,
      search,
      ...updateData
    });

    successHandler(
      { response, status: response.status },
      {
        notifyOnSuccess: true,
        notifyOnFailed: true
      }
    );
    return {
      data: response.data,
      status: response.status
    };
  } catch (error: AxiosError | any) {
    return errorHandler(error);
  }
};