export const RESET_STATE = 'REPORT_RESET_STATE';
export const CURRENT_ITEM = 'REPORT_CURRENT_ITEM';

export const REQUEST_LOADING = 'REPORT_REQUEST_LOADING';
export const REQUEST_SUCCESS = 'REPORT_REQUEST_SUCCESS';
export const REQUEST_FAILED = 'REPORT_REQUEST_FAILED';

export const CURRENT_ACTION = 'REPORT_CURRENT_ACTION';
export const RESET_ACTION = 'REPORT_RESET_ACTION';

export const UPDATE_VISIBILITY = 'UPDATE_VISIBILITY';

export const UPDATE_PAGINATION = 'REPORT_UPDATE_PAGINATION';

export const RESET_LIST = 'REPORT_RESET_LIST';

export const UPDATE_REPORT_DATA = 'UPDATE_REPORT_DATA';

export const UPDATE_REPORT_DATA_LIST = 'UPDATE_REPORT_DATA_LIST';

export const REMOVE_LIST_ITEM = 'REMOVE_LIST_ITEM';

export const ADD_LIST_ITEM = 'ADD_LIST_ITEM';

export const UPDATE_REPORT_DATA_OBJECT = 'UPDATE_REPORT_DATA_OBJECT';

export const FETCH_REPORTS_REQUEST = 'FETCH_REPORTS_REQUEST';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';
export const FETCH_REPORTS_FAILURE = 'FETCH_REPORTS_FAILURE';