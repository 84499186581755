import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  List,
  Spin,
  Skeleton,
  Divider,
  Button
} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { report } from '../../../../redux/report/actions';
import { reportList } from '../../../../redux/report/selectors';
import {
  CloudDownloadOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import { downloadDoc } from '../../../../service/report.service';
import WorldDoc from '../../../../assets/icons/worlddoc.jpeg';
import PdfImage from '../../../../assets/icons/pdf.svg';
import EnPng from '../../../../assets/icons/en.png';
import FrPng from '../../../../assets/icons/fr.png';

const ReportList2 = () => {
  const dispatch = useDispatch();
  const { reports, loading, hasMore, currentPage, total_records } = useSelector(reportList);

  const loadMoreData = () => {
    if (loading || !hasMore) return;
    dispatch<any>(report.fetchReports(currentPage, 10));
  };

  useEffect(() => {
    loadMoreData(); // Initial data load
  }, []);

  return (
    <div
      id="scrollableDiv"
      style={{
        height: '80vh',
        overflow: 'auto',
        padding: '0 24px',
        border: '1px solid #e8e8e8',
        borderRadius: '10px',
        background: '#fff'
      }}
    >
      <InfiniteScroll
        dataLength={reports.length}
        next={loadMoreData}
        hasMore={hasMore}
        loader={<Skeleton avatar paragraph={{ rows: 10 }} active />}
        scrollableTarget="scrollableDiv"
      >
        <List
          dataSource={reports}
          renderItem={(item: any) => (
            <List.Item
              key={item.id}
              actions={[<a
                key="list-loadmore-edit"
                onClick={() => downloadDoc(item.report_url, 'docx')}
              >
                <CloudDownloadOutlined /> Download
              </a>]}
            >
              <List.Item.Meta
                avatar={
                  <img
                    src={item.type == 'pdf' ? PdfImage : WorldDoc}
                    height={25}
                    width={25}
                    alt='doc'
                  />
                }
                title={<div className='flex gap-2 items-center'>
                  <span>
                    <img
                      src={item.language == 'fr' ? FrPng : EnPng}
                      alt='flag'
                      height={20}
                      width={20}
                    />
                  </span>
                  {item.name} • <span
                    className='text-sm text-blue-gray-400'
                  >{new Date(
                      item.create_date
                    ).toLocaleDateString()} - {new Date(item.create_date).toLocaleTimeString()}</span>
                </div>}
                description={item.summary}

              />
            </List.Item>
          )}
        />
        {loading && hasMore && (
          <div style={{ textAlign: 'center', padding: '24px 0' }}>
            <Spin />
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
};

export default ReportList2;
