import {
  BugAntIcon,
  DocumentChartBarIcon,
  ServerStackIcon
} from '@heroicons/react/24/outline';
import {
  Card,
  Table,
  Spin,
  CollapseProps,
  Divider,
  Progress,
  Tag
} from 'antd';
import { useEffect, useRef, useState } from 'react';
import GaugeChart from 'react-gauge-chart';
import { TopAssetColumns, TopVulsColumns } from './TableConfig';
import PieChart from '../../UI/chart/PieChart';
import BarChartDouble from '../../UI/chart/BarChartDouble';
import dashboardService from '../../../service/dashboard.service';
import { DASHBOARD_PROPS } from './type';
import CheckBoxField from '../../UI/checkbox/CheckBoxField';
import { AVAILABILITY, VULNERABILITY_STATUS } from '../../../config/enum';
import { Typography } from 'antd';
import projectService from '../../../service/project.service';
import { useNavigate } from 'react-router-dom';
import AssetIconType from '../../utils/asset/AssetTypeIcon';
import {
  ArrowDownOutlined
} from '@ant-design/icons';
import VulnBySeverity from './dash/vulnBySeverity';
import RiskHeatMap from './dash/riskhitmap';
import RiskBusinessImpact from './dash/RiskBusinessImpact';
import CustomApexChart from './dash/ChartLine';
import AttendanceBar from './dash/barline';
import TreemapCPE from '../../UI/chart/Treemap';

const { Title } = Typography;

const DashboardGeneral = () => {

  const [data, setdata] = useState<DASHBOARD_PROPS>();
  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await dashboardService.getGlobalDashboard();
      let result: DASHBOARD_PROPS = data.data.result;
      result.vulnerability_by_severity_pie = {
        data: result.vulnerability_by_severity?.map((vuln) => vuln.value),
        labels: result.vulnerability_by_severity?.map((vuln) => vuln.name)
      };
      setdata(result);
      setIsLoading(false);
    };
    getData();
  }, []);

  const navigate = useNavigate();

  const formikRef = useRef<any>(null);
  const formikRef2 = useRef<any>(null);
  const formikRef3 = useRef<any>(null);
  const contentListNoTitle: Record<string, React.ReactNode> = {
    Pie: <PieChart
      seriesDefault={data?.vulnerability_by_severity_pie?.data}
      label={data?.vulnerability_by_severity_pie?.labels}
      colors={['#6B21A8', '#EF4444', '#FACC15', '#32CD32', '#3B82F6']}
    />,
    Bar: <div>
      <BarChartDouble
        dataLabels
        distributed
        title='Most vulnerable projects'
        categories={data?.vulnerability_by_severity_pie?.labels}
        series={[{
          data: data?.vulnerability_by_severity_pie?.data
        }]}
      />
    </div>
  };
  const contentListNoTitle2: Record<string, React.ReactNode> = {
    Pie: <PieChart
      seriesDefault={[data?.host_risk_distribution?.critical || 0, data?.host_risk_distribution?.high || 0, data?.host_risk_distribution?.medium || 0, data?.host_risk_distribution?.low || 0]}
      label={['Critical', 'High', 'Medium', 'Low']}
      colors={['#6B21A8', '#EF4444', '#FACC15', '#32CD32', '#3B82F6']}
    />,
    Bar: <div>
      <BarChartDouble
        dataLabels
        distributed
        title='Most vulnerable projects'
        categories={data?.top_10_assets_by_vulns.categories}
        series={data?.top_10_assets_by_vulns.series}
      />
    </div>
  };

  const contentListRiskTable: Record<string, React.ReactNode> = {
    vulns: <Table
      rowKey="id"
      style={{ borderRadius: 10 }}
      columns={TopVulsColumns}
      dataSource={data?.row_top_risk || []}
      size="small"
      pagination={false}
      rowClassName={() => 'cursor-pointer'}
      showHeader={false}
      onRow={(row) => ({
        onClick: () => navigate(`/vulnerability/${row.id}`)
      })}
    />,
    assets: <Table
      rowKey="id"
      style={{ borderRadius: 10 }}
      columns={TopAssetColumns}
      dataSource={data?.row_top_risk_assets || []}
      size="small"
      onRow={(row) => ({
        onClick: () => navigate(`/asset/${row.id}`)
      })}
      pagination={false}
      rowClassName={() => 'cursor-pointer'}
      showHeader={false}
    />,
    projects: <Table
      rowKey="id"
      style={{ borderRadius: 10 }}
      columns={TopVulsColumns}
      dataSource={data?.most_vulnerable_projects || []}
      size="small"
      pagination={false}
      rowClassName={() => 'cursor-pointer'}
      showHeader={false}
      onRow={(row) => ({
        onClick: () => navigate(`/project/${row.id}`)
      })}
    />
  };
  const [activeTabKey, setActiveTabKey] = useState<string>('Pie');
  const [activeTabKey2, setActiveTabKey2] = useState<string>('Pie');
  const [activeTabKeyRisk, setActiveTabKeyRisk] = useState<string>('vulns');
  const severities = [{
    name: 'critical',
    color: 'bg-purple-500',
    label: 'C'
  }, {
    name: 'high',
    color: 'bg-red-500',
    label: 'H'
  }, {
    name: 'medium',
    color: 'bg-yellow-500 text-black',
    label: 'M'
  }, {
    name: 'low',
    color: 'bg-green-500',
    label: 'L'
  }, {
    name: 'information',
    color: 'bg-blue-500',
    label: 'I'
  }];
  const items: CollapseProps['items'] = [{
    key: '2',
    label: 'Status',
    children: <div>   {VULNERABILITY_STATUS.map(v => (
      <CheckBoxField
        key={v.value}
        name={'status.' + v.value}
        label={v.label}
      />
    ))}</div>
  }];

  const initialValues = {
    severity: {
      high: false,
      medium: false,
      critical: false,
      low: false,
      information: false
    },
    status: {
      open: false,
      detected: false,
      mitigated: false,
      analysed: false,
      solved: false,
      reopened: false,
      risk_accepted: false,
      false_positive: false,
      fixed: false
    },
    sources: [],
    criticality: [],
    data_sensitivity: [],
    network_exposure: [],
    compliance: [],
    availability: [],
    risk_score: [0, 100],
    exploit: false,
    malware: false,
    zero_day: false,
    unpatched: false
  };

  const [projects, setprojects] = useState([]);
  const [loadingPorject, setloadingPorject] = useState(false);
  useEffect(() => {
    const getProjects = async () => {
      setloadingPorject(true);
      const projects = await projectService.getListName();
      setprojects(projects.projects.map((v: any) => ({
        label: v.name + ` (${v.vulns_count})`,
        value: v.name
      })));
      setloadingPorject(false);
    };
    getProjects();
  }, []);

  return <Spin tip="Loading" size="small" spinning={isLoading}>
    <div className="flex flex-col">
      <h2
        className="text-title-md font-semibold text-black dark:text-white mb-5"
      >Dashboard</h2>
      <div className="">
        <div
          className="grid grid-cols-1 gap-5 mt-2 sm:grid-cols-2 lg:grid-cols-4"
        >
          {/* Content start */}
          <div>
            <div
              className="bg-white flex justify-center items-center text-center flex-row p-3 border border-gray rounded-lg h-[150px]"
            >
              <Title level={5}>Global Risk<br /> Score</Title>

              <GaugeChart
                className='h-[10px] w-[180px] flex justify-center items-center'
                style={{
                  height: '10%'
                }}
                id="gauge-chart3"
                nrOfLevels={30}
                colors={['#5BE12C', '#F5CD19', '#EA4228']}
                arcWidth={0.3}
                percent={data?.global_risk_score ? data?.global_risk_score / 100 : 0}
                textColor='#000'
                needleColor='#d0d0d0'
                arcPadding={0.02}
              />
            </div>
          </div>
          {/* Content start */}
          <div>
            <div
              className="bg-white border border-gray rounded-lg"
            >
              <div className="p-4 flex items-start justify-between h-[70px]">
                <div className="flex flex-col space-y-2">
                  <span className="text-gray-400">Total Project</span>
                  <span
                    className="text-lg font-semibold"
                  >{data?.total_project}</span>
                </div>
                <div className="p-4 bg-gray-200 rounded-full bg-meta-2">
                  <DocumentChartBarIcon
                    className="h-8 w-8 text-indigo-500"
                  />
                </div>
              </div>
              <div className='h-[80px]'>
                <AttendanceBar
                  data={data?.project_risk_ranges}
                />
              </div>
            </div>
          </div>
          {/* Content start */}
          <div>
            <div
              className="bg-white border border-gray rounded-lg "
            >
              <div
                className="px-4 pt-4 flex items-start justify-between h-[150px]"
              >
                <div className="flex flex-col space-y-2">
                  <span
                    className="text-gray-400"
                  >Total of vulnerabilities</span>
                  <span
                    className="text-lg font-semibold"
                  >{data?.total_vuln || 0}</span>
                </div>
                <div className="p-4 bg-gray-200 rounded-full bg-meta-2">
                  <BugAntIcon
                    className="h-8 w-8 text-red-500"
                  />
                </div>
              </div>
              <div className="relative">
                <div className='absolute -bottom-[25px] w-full'>
                  <CustomApexChart
                    data={data?.vulnerabilities_by_month}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Content start */}
          <div>
            <div
              className="bg-white p-4 border border-gray rounded-lg"
            >
              <div className="flex items-start justify-between h-[95px]">
                <div className="flex flex-col space-y-2">
                  <span className="text-gray-400">Total of assets</span>
                  <span
                    className="text-lg font-semibold"
                  >{data?.total_asset || 0}</span>
                </div>
                <div className="p-4 bg-gray-200 rounded-full bg-meta-2">
                  <ServerStackIcon
                    className="h-8 w-8 text-purple-500"
                  />
                </div>
              </div>
              <div className='flex gap-2'>
                {/* Start Content */}
                <div
                  className=" flex flex-row gap-2 justify-center text-center items-center"
                >
                  <AssetIconType
                    type='web'
                    size='sm'
                  /> {data?.assets_web || 0}
                </div>
                <Divider type="vertical" />
                {/* Start Content */}
                <div
                  className=" flex flex-row gap-2 justify-center text-center items-center"
                >
                  <AssetIconType type='infrastructure' size='sm' />
                  {data?.assets_infrastructure || 0}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-4 mt-4">
          <div className="w-full xl:w-1/2">
          </div>
        </div>

        <div className="flex gap-4 mt-4">
          <div className="w-full xl:w-6/12">
            <Card
              size='small'
              className=''
            >
              <div className="px-5 pt-5">
                <Typography.Title level={4}>Risk Rating</Typography.Title>
                <Typography.Paragraph
                >Total of Risk ...</Typography.Paragraph>
              </div>
              <PieChart
                seriesDefault={data?.risk_rating?.data}
                label={data?.risk_rating?.series}
                colors={['#7D3C98', '#E74C3C', '#F4D03F', '#27AE60', '#3498DB']}
                showDataLabels
                showPercentage={false}
                type='donut'
                height={213}
              />
            </Card>
          </div>
          <div className="w-full xl:w-8/12">
            <RiskHeatMap
              data={data?.risk_heatmap}
            />
          </div>
        </div>
        <div className="w-full xl:w-12/12 mt-5">
          <RiskBusinessImpact
            categories={['Finance', 'Operational', 'Brand Damage', 'Data Disclosure', 'Legal Regulatory']}
            data={data?.impact_by_vuln_severity.data}
            total={data?.impact_by_vuln_severity.total}
          />
        </div>

        <Card className='mt-5'>
          <Typography.Title level={4}>Asset Dashboard</Typography.Title>
          <Typography.Paragraph>Some description about Asset Dashb </Typography.Paragraph>
          <div className="flex gap-4 mt-4">
            <div className="w-full xl:w-1/4">
              <PieChart
                seriesDefault={data?.os_busness_impact.data}
                label={data?.os_busness_impact.series}
                colors={['#7D3C98', '#E74C3C', '#F4D03F', '#27AE60', '#BABDBA']}
                showDataLabels
                title="Asset by business sensitivity"
                showPercentage={false}
                type='donut'
                compact
                showLegend={false}
                height={300}
              />
            </div>
            <div className="w-full xl:w-1/4">
              <PieChart
                seriesDefault={data?.asset_by_os.data}
                label={data?.asset_by_os.categories}
                showDataLabels
                showPercentage={false}
                type='donut'
                title='Asset by OS'
                compact
                monochrome
                showLegend={false}
                height={300}
              />
            </div>
            <div className="w-full xl:w-1/4">
              <PieChart
                seriesDefault={[10, 10, 10, 10]}
                label={['Critical', 'High', 'Medium', 'Low']}
                showDataLabels
                showPercentage={false}
                type='donut'
                title='Asset by category'
                compact
                monochrome
                showLegend={false}
                height={300}
              />
            </div>
            <div className="w-full xl:w-1/4">
              <PieChart
                title='Asset by Application'
                label={data?.asset_by_application.categories}
                seriesDefault={data?.asset_by_application.data}
                showDataLabels
                showPercentage={false}
                type='donut'
                compact
                monochrome
                showLegend={false}
                height={300}
              />
            </div>
          </div>
        </Card>
        <div className="flex gap-4 mt-4">
          <div className="w-full">
            <VulnBySeverity
              data={data?.vulnerabilities_by_severity_status}
              stats={data?.vulnerability_stats}
            />
          </div>
        </div>
        <div className="flex gap-4 mt-4">
          <div className="w-full xl:w-4/12 h-full mt-4">
            <Card>
              <BarChartDouble
                dataLabels
                distributed
                title='Most vulnerable projects'
                categories={data?.top_10_assets_by_vulns.categories}
                series={data?.top_10_assets_by_vulns.series}
              />
            </Card>
          </div>
          <div className="w-full xl:w-8/12 mt-4">
            <Card>
              <Typography.Title
                level={4}
              >Vul by application and severity</Typography.Title>
              {/* Write me some parapgraph for this section  */}
              <Typography.Paragraph>Vulnerability </Typography.Paragraph>
              {/* Windows = 10 Critical, 20 high, .... */}
              <TreemapCPE
                data={data?.treemap_series ? data?.treemap_series : []}
              />
            </Card>
          </div>
        </div>
      </div>

      <div className="w-full xl:w-1/3 hidden">
        <Table<any>
          pagination={false}
          title={() => 'Host by operating systems'}
          // className='min-h-[400px] max-h-[400px] overflow-auto'
          className='h-[400px]'
          columns={[
            {
              title: 'Vendor',
              dataIndex: 'vendor'
            }, {
              title: 'Product',
              dataIndex: 'product'
            }, {
              title: 'Asset count',
              dataIndex: 'count',
              align: 'center',
              render: (value, record) => <Tag
                color='geekblue'
              >{value}</Tag>
            }, {
              title: '',
              dataIndex: 'percentage',
              align: 'right',
              render: (value, record) =>
                <Progress
                  type="dashboard"
                  percent={value}
                  size={50}
                  format={() => `${value}%`}
                />
            }
          ]}
          scroll={{ y: 270 }}
          dataSource={data?.os_count.map((os) => ({
            key: os.vendor,
            vendor: os.vendor,
            product: os.product,
            count: os.count,
            percentage: os.percentage
          }))}
          footer={() => 'Total: ' + data?.os_count?.reduce((acc, os) => acc + os.count, 0)}
          size="small"
        />
      </div>
    </div>
  </Spin >;
};

export default DashboardGeneral;