import {
  Text,
  View,
  StyleSheet
} from '@react-pdf/renderer';
const ProjectOverview = ({ totalVuln = 0, totalAsset = 0, totalRisk = 0 }) => {

  const getBgColorRisk = (score: number) => {
    if (score === 0) return 'green'; // Low
    if (score <= 30.9) return 'blue'; // Informational
    if (score <= 60.9) return '#f1c40f'; // Medium
    if (score <= 88.9) return 'red'; // High
    return 'purple'; // Critical
  };

  const styles = StyleSheet.create({
    headerContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 10,
      backgroundColor: '#f2f2f2', // Light grey background for the header section
      borderRadius: 10,
      marginBottom: 10
    },
    headerItem: {
      flex: 1, // Each item will take up equal space
      alignItems: 'center' // Center the items horizontally
    },
    headerTitle: {
      fontSize: 14,
      marginBottom: 5,
      fontWeight: 'bold'
    },
    headerValue: {
      fontSize: 22,
      fontWeight: 'bold'
    }
    // ...other styles if necessary
  });

  return <View style={styles.headerContainer}>
    <View style={styles.headerItem}>
      <Text style={styles.headerTitle}>Total Vulnerability</Text>
      <Text style={styles.headerValue}>{totalVuln}</Text>
    </View>
    <View style={styles.headerItem}>
      <Text style={styles.headerTitle}>Total Asset</Text>
      <Text style={styles.headerValue}>{totalAsset}</Text>
    </View>
    <View style={styles.headerItem}>
      <Text style={styles.headerTitle}>Risk Score</Text>
      <Text 
        style={[styles.headerValue, { color: getBgColorRisk(totalRisk) }]}
      >{totalRisk}</Text>
    </View>
  </View>;
};

export default ProjectOverview;