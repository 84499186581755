import { notification } from 'antd';
import codeMessage from './codeMessage';

const errorHandler = (error) => {

  if (error && error.code) {
    const message = error.response.data.message;

    const errorText = message || codeMessage[error.response.status];
    notification.config({
      duration: 10,
      maxCount: 2
    });
    notification.error({
      message: 'Request error',
      description: errorText
    });
    return {
      success: false,
      result: null,
      message: errorText,
      data: error.response.data
    };
  } else {
    notification.config({
      duration: 5,
      maxCount: 1
    });

    if (navigator.onLine) {
      // Code to execute when there is internet connection
      notification.error({
        message: 'Problem connecting to server',
        description: 'Cannot connect to the server, Try again later'
      });
      return {
        success: false,
        result: null,
        message: 'Cannot connect to the server, Check your internet network'
      };
    } else {
      // Code to execute when there is no internet connection
      notification.error({
        message: 'No internet connection',
        description: 'Cannot connect to the Internet, Check your internet network'
      });
      return {
        success: false,
        result: null,
        message: 'Cannot connect to the server, Check your internet network'
      };
    }
  }
};

export default errorHandler;
